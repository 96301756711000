<template>
  <div class="comparison__cards">
    <div class="comparison__card" v-if="currentCard !== 'Color Palette'">
      <h3 class="comparison__card-title">
        Site Palette vs. Color Palette
      </h3>
      <p class="comparison__card-text">
        See how Site Palette outshines Color Palette with enhanced exporting options, more flexibility in color generation, and seamless design tool integration.
      </p>
      <router-link to="/vs-color" class="comparison__card-link" aria-label="link to the page with differences">
        Read more
        <img src="@/images/arrow-read-more.svg" alt="">
      </router-link>
    </div>
    <div class="comparison__card" v-if="currentCard !== 'Extract Colors DevTool'">
      <h3 class="comparison__card-title">
        Site Palette vs. Extract Colors DevTool
      </h3>
      <p class="comparison__card-text">
        Discover the differences between Site Palette and Extract Colors DevTool. Learn why Site Palette's advanced export formats and palette management give it the edge.
      </p>
      <router-link to="/vs-extract" class="comparison__card-link" aria-label="link to the page with differences">
        Read more
        <img src="@/images/arrow-read-more.svg" alt="">
      </router-link>
    </div>
    <div class="comparison__card" v-if="currentCard !== 'Website Color Palette'">
      <h3 class="comparison__card-title">
        Site Palette vs. Website Color Palette
      </h3>
      <p class="comparison__card-text">
        Discover the key differences between Site Palette and Website Color Palette, and why Site Palette offers more flexibility and design tool integration.
      </p>
      <router-link to="/vs-website" class="comparison__card-link" aria-label="link to the page with differences">
        Read more
        <img src="@/images/arrow-read-more.svg" alt="">
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentCard: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  }
};
</script>

<style scoped>
.comparison__cards {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  min-height: 294px;
}

.comparison__card {
  flex: 1 1 352px;
  max-width: 392px;
  min-width: 352px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid rgba(41, 44, 46, 0.20);
}

.comparison__card-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  color: #17191D;
  margin-bottom: 16px;
  min-height: 44px;
}

.comparison__card-text {
  font-family: "Avenir", Helvetica, Arial;
  color: #3D3D3D;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
}

.comparison__card-link {
  padding: 13px 25px;
  font-family: "Avenir", Helvetica, Arial;
  color: #7693FC;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  border: 1px solid #7693FC;
  background: #fff;
  margin-top: auto;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.comparison__card-link:hover {
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  .comparison__cards{
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .comparison__card {
    flex-basis: auto;
    padding: 16px;
    width: 100%;
    max-width: none;
  }
  .comparison__card-title {
    margin-bottom: 12px;
    min-height: auto;
  }
  .comparison__card-text {
    margin-bottom: 24px;
  }
}
</style>
