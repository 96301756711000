import { createApp } from "vue";
import App from "./App";
import components from "@/components/UI";
import router from "./router/router";
import store from "./store";
import VueGtag from "vue-gtag";

const app = createApp(App).use(store);

app.use(VueGtag, {
  config: { id: "G-56D9HTBZ2J" }
})

components.forEach((component) => {
  app.component(component.name, component);
});

app.use(router).mount("#app");
