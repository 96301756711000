<template>
  <div class="result">
    <section
      class="info"
      :class="{ 'info-without-banner': !$store.state.isBannerShow }"
    >
      <div class="info__header">
        <div class="info__header-data" v-show="$store.getters.userToken">
          <img class="logo" src="@/images/app-icon.png" />
          <router-link to="/palettes" class="header__link">
            My palettes
          </router-link>
          <p class="header__slash">/</p>
          <p class="header__url-domain">{{ $store.state.onePalette.title }}</p>
          <a
            class="header__url-link"
            target="_blank"
            :href="$store.state.onePalette.url"
          ></a>
        </div>
        <div
          class="info__trial"
          v-show="!isUserPlanTrial && $store.getters.userToken && !isExpired"
        >
          <p class="info__trial-text">{{ planInfo }}</p>
          <router-link
            to="/profile"
            class="info__trial-upgrade"
            v-show="!isUserPlanLifetime"
            >{{ btnUpgradeText }}</router-link
          >
        </div>
        <div class="info__header_unlogged" v-show="!$store.getters.userToken">
          <router-link to="/" class="logo__unlogged">
            <img src="@/images/logo-black.svg" />
          </router-link>
          <div class="info__header-get-ext">
            <GetExtBtn />
          </div>
        </div>
      </div>
      <div
        class="share-box"
        :class="{ 'share-box_with-price-card': isUserPlanTrial || isExpired }"
      >
        <div class="image-box">
          <div class="browser__bar">
            <div class="browser__dots"></div>
            <div class="browser__address">
              <a
                class="browser__url"
                target="_blank"
                id="site-url"
                :href="$store.state.onePalette.url"
                >{{ $store.state.onePalette.url }}</a
              >
            </div>
          </div>
          <div
            class="browser__body"
            :class="{
              'browser__body_with-price-cards': isUserPlanTrial || isExpired,
            }"
          >
            <img
              id="target"
              class="browser__image"
              :src="$store.state.onePalette.image_url"
            />
          </div>
        </div>

        <button
          class="btn-share js-share"
          :class="{ 'btn-share_copied': isCopied }"
          @click="share"
        >
          <div
            class="btn-share__icon"
            :class="{ 'btn-share__icon_copied': isCopied }"
          ></div>
          <p class="btn-share__txt">{{ btnShareText }}</p>
        </button>
        <div class="share__url-box">
        </div>
        <div v-show="isUserPlanTrial || isExpired">
          <div class="share-box__trial-info" v-show="!isExpired">
            <p class="share-box__trial-left">
              Trial:
              <span
                :class="[
                  isMuchTime
                    ? 'share-box__trial-left_much'
                    : 'share-box__trial-left_little',
                ]"
                >{{ trialPlanLeft }}</span
              >
            </p>
            <p class="share-box__trial-text">Upgrade for unlimited access</p>
          </div>
          <div class="share-box__trial-info" v-show="isExpired">
            <p class="share-box__trial-left">{{ textForExpiredPlan }}</p>
            <p class="share-box__trial-text">Upgrade your plan to continue</p>
          </div>

          <div class="form__plan-price-cards">
            <PriceCard
              v-for="info in priceInfo"
              :priceInfo="info"
              :selectedSubs="selectedSubs"
              @set-selected-sub="setSelectedSub"
              :key="info.type"
            />
          </div>
        </div>
      </div>

      <KarmaBot />
      <div class="madeby-box" v-show="!$store.state.isBannerShow">
        Made in New Zealand <span class="madeby__flag">🇳🇿</span> by
        <a href="https://karmabot.chat/" target="_blank" class="madeby__url"
          >Karma</a
        >
        team
      </div>
      <div class="madeby-box" v-show="$store.state.isBannerShow">
        Made in New Zealand <span class="madeby__flag">🇳🇿</span> at
        <a href="https://sliday.com/" target="_blank" class="madeby__url"
          >Sliday</a
        >
      </div>
    </section>
    <section class="colors">
      <PaletteCard
        :card="cardCube"
        :githubUrl="'https://github.com/amonks/colorcube-js'"
      />
      <PaletteCard
        :card="cardMedian"
        :githubUrl="'https://github.com/lokesh/color-thief'"
      />
      <PaletteCard
        :card="cardMaterial"
        :githubUrl="'https://github.com/jariz/vibrant.js'"
      />
    </section>
    <section class="madeby_mob">
      Made in New Zealand <span class="madeby__flag">🇳🇿</span> by
      <a href="https://karmabot.chat/" target="_blank" class="madeby__url"
        >Karma</a
      >
      team
    </section>
  </div>
</template>

<script>
import ClipboardJS from "clipboard";
import PaletteCard from "@/components/PaletteCard";
import KarmaBot from "@/components/KarmaBot";
import PriceCard from "@/components/PriceCard";
import GetExtBtn from "@/components/GetExtensionButton";
import mainApi from "../utils/MainApi";
import store from "@/store";
import {
  getInfoAboutPlanTimeLeft,
  getInfoAboutCurrentPlan,
} from "../utils/utils";

export default {
  components: {
    PaletteCard,
    KarmaBot,
    PriceCard,
    GetExtBtn,
  },
  data() {
    let url = window.location.href;
    let isBannerShow = store.state.isBannerShow;
    let isUserPlanTrial = store.state.user
      ? store.state.user.plan.toLowerCase() === "trial"
      : false;
    let isUserPlanLifetime = store.state.user
      ? store.state.user.plan.toLowerCase() === "lifetime"
      : false;
    let priceSubsBtnText = store.state.user
      ? (store.state.user.expired || isUserPlanTrial ? "Subscribe" : "Manage")
      : '';
    let isExpired = store.state.user ? store.state.user.expired : false;
    let planInfo = store.state.user
      ? store.state.user.planInfo + ": " + store.state.user.planTimeLeft
      : "";
    let textForExpiredPlan =
      store.state.user && store.state.user.expired
        ? store.state.user.plan.toLowerCase() === "trial"
          ? "Trial has expired"
          : "Subscription has expired"
        : "";
    let footerUrl = store.state.isBannerShow
      ? "https://sliday.com/"
      : "https://karmabot.chat/";
    new ClipboardJS(".js-share", {
      text: function (trigger) {
        return window.location.href;
      },
    });

    return {
      url,
      isBannerShow,
      isUserPlanTrial,
      isUserPlanLifetime,
      isExpired,
      trialPlanLeft: "",
      planInfo,
      isPaymentSending: false,
      btnUpgradeText: "Upgrade",
      isCopied: false,
      btnShareText: "Share this palette",
      priceInfo: [
        {
          title: "Subscription",
          price: "$2.50/mo",
          bill: "billed annually",
          btnText: priceSubsBtnText,
          type: "sub",
        },
        {
          title: "Lifetime",
          price: "$99",
          bill: "billed once",
          btnText: "Purchase",
          type: "life",
        },
      ],
      selectedSubs: "sub",
      isMuchTime: true,
      textForExpiredPlan,
      footerUrl,
    };
  },
  methods: {
    share() {
      this.btnShareText = "Link is copied!";
      this.isCopied = true;
      setTimeout(() => {
        this.btnShareText = "Share this palette"
        this.isCopied = false;
      }, 1000)
    },
    setSelectedSub(val) {
      this.selectedSubs = val;
    },
    closeBanner: function (evt) {
      evt.preventDefault();
      if (store.state.userToken) {
        store.dispatch("setBannerHide", true);
      }
      this.isBannerShow = false;
      this.footerUrl = "https://karmabot.chat/";
    },
    sendPayment: function () {
      this.isPaymentSending = true;
      this.btnUpgradeText = "";
      this.$store.dispatch("sendPayment");
    },
    selectSubs: function () {
      this.isSubsSelected = true;
    },
    selectPurch: function () {
      this.isSubsSelected = false;
    },
  },
  mounted() {
    this.isMuchTime = parseInt(this.trialPlanLeft, 10) >= 2;
  },
  computed: {
    cardCube() {
      return {
        name: "Color cube",
        desc: "3D grid for local hit count maxima search.",
        url: this.$store.getters.onePalette.url,
        colorArray: this.$store.getters.onePalette.colors.cube,
      };
    },
    cardMedian() {
      return {
        name: "Median cut",
        desc: "Groups alike colours in 3D, sorts by volume, returns base colors per block.",
        url: this.$store.getters.onePalette.url,
        colorArray: this.$store.getters.onePalette.colors.thief,
      };
    },
    cardMaterial() {
      return {
        name: "Material design",
        desc: "Based on Palette class from Android SDK.",
        url: this.$store.getters.onePalette.url,
        colorArray: this.$store.getters.onePalette.colors.vibrant,
      };
    },
  },
  beforeMount() {
    this.isPaymentSending = false;
    this.btnUpgradeText = "Manage";
    this.trialPlanLeft = store.state.user
      ? store.state.user.expired
        ? "has expired"
        : getInfoAboutPlanTimeLeft(store.state.user, "short")
      : "";
  },
  beforeRouteEnter(to, from, next) {
    const paletteId = to.query.id;
    mainApi
      .getPaletteById(paletteId)
      .then((data) => {
        store.dispatch("setOnePalette", data);
        next();
      })
      .catch((err) => {
        store.dispatch("openNotFoundPage");
      });
    if (store.getters.userToken) {
      mainApi
        .getUser(store.getters.userToken)
        .then((data) => {
          data.user.planInfo = getInfoAboutCurrentPlan(data.user);
          if (data.user.plan !== "lifetime") {
            if (data.user.expired) {
              data.user.planTimeLeft =
                data.user.plan.toLowerCase() === "trial"
                  ? "Trial has expired"
                  : "Subscription has expired";
            } else {
              data.user.planTimeLeft = getInfoAboutPlanTimeLeft(
                data.user,
                "short"
              );
            }
          } else {
            data.user.planTimeLeft = getInfoAboutPlanTimeLeft(data.user);
          }
          store.dispatch("setUser", data.user);
          next();
        })
        .catch((err) => {
          store.dispatch("openNotFoundPage");
        });
    }
  },
};
</script>

<style scoped>
.result {
  display: flex;
  transition: all ease 0.5s;
  background-color: #f2f6f9;
}

.info {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  flex: none;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  background-color: #f2f6f9;
  padding: 25px 30px 0;
  min-height: 100vh;
}

.info__header {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 12px;
  width: 100%;
  margin-bottom: 24px;
}

.info__header_unlogged {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  width: 100%;
  flex-wrap: wrap;
}

.info__header-data {
  display: flex;
  align-items: baseline;
  width: 100%;
}

.logo {
  width: 14px;
  height: 14px;
  margin-right: 4.5px;
  transition: opacity ease 0.15s, visibility ease 0.15s, padding ease 0.3s,
    height ease 0.15s;
}

.logo__unlogged {
  transition: opacity ease 0.15s, visibility ease 0.15s, padding ease 0.3s,
    height ease 0.15s;
}

.logo:hover,
.logo__unlogged:hover {
  opacity: 0.8;
}

.header__link {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #3080f2;
  margin-right: 6px;
  transition: opacity ease 0.15s, visibility ease 0.15s, padding ease 0.3s,
    height ease 0.15s;
  min-width: 90.64px;
}

.header__link:hover {
  opacity: 0.8;
}

.header__url-domain {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #17191d;
  margin-right: 6px;
}
.header__slash {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.5);
  margin-right: 6px;
}
.header__url-link {
  width: 13px;
  height: 13px;
  min-width: 13px;
  background-image: url("../images/external-link.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  align-self: center;
}

.info__trial {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.info__trial-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.7);
}

.info__trial-upgrade {
  font-family: "GeneralSans", Helvetica, Arial;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #3080f2;
  border: none;
  cursor: pointer;
  background: transparent;
  width: 56px;
  height: 19px;
  position: relative;
}

.btn-loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 2px solid transparent;
  border-top-color: #3080f2;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.share-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  flex: 1;
  justify-content: center;
}

.share-box_with-price-card {
  justify-content: start;
}

.image-box {
  /*max-width: 100%;*/
  max-width: 80%;
  margin: 0 auto 40px;
  box-shadow: 0px 10px 40px -10px rgb(24 65 107 / 35%);
}

.browser__bar {
  height: 35px;
  background: #e7edf3;
  border-radius: 4px 4px 0 0;
  display: -webkit-flex;
  display: flex;
  justify-content: flex-end;
  padding: 7px 0 8px;
  position: relative;
  align-items: center;
}

.browser__dots {
  position: relative;
  width: 8px;
  height: 8px;
  background: #c7d1da;
  border-radius: 10px;
  margin-right: 5%;
}

.browser__dots::after,
.browser__dots::before {
  width: 8px;
  height: 8px;
  background: #c7d1da;
  border-radius: 10px;
  position: absolute;
  content: "";
}

.browser__dots::before {
  left: 13px;
}

.browser__dots::after {
  right: 13px;
}

.browser__address {
  width: 86%;
  margin-right: 2%;
  height: 100%;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  white-space: nowrap;
  font-size: 9px;
  line-height: 20px;
  padding-left: 10px;
  color: #96abb7;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 5px;
}

.browser__address a {
  text-decoration: none;
  color: #96abb7;
  transition: all ease 0.3s;
}

.browser__address a:hover {
  color: #67808e;
}

.browser__body {
  border-radius: 0 0 4px 4px;
  overflow: scroll;
  max-height: 20vw;
}

.browser__body_with-price-cards {
  max-height: 15vw;
}

.browser__image {
  max-width: 100%;
}

.btn-share {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 25px;
  gap: 10px;
  min-width: 224px;
  height: 50px;
  background: #ffffff;
  border: 2px solid #7693fc;
  border-radius: 10px;
  cursor: pointer;
}
.btn-share_copied {
  cursor: auto;
}

.btn-share:hover {
  opacity: 0.9;
  box-shadow: none;
}
.btn-share_copied:hover {
  opacity: 1;
}

.btn-share__icon {
  width: 24px;
  height: 24px;
  background-image: url("../images/share-icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.btn-share__icon_copied {
  background-image: url("../images/copied.svg");
}

.btn-share__txt {
  font-family: "GeneralSans", Helvetica, Arial;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #7693fc;
  padding: 0;
  margin: 2px 0 0 0;
}

.button_hide {
  display: none;
}

.share__url {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  transition: all ease 0.3s;
  display: none;
  margin-top: 12px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: rgba(0, 0, 0, 0.4) 1px solid;
}

.share__url_show {
  display: inline;
}

.share__url-box {
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  max-width: 98%;
  margin-bottom: 24px;
}

.share-box__trial-info {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  align-items: center;
}

.share-box__trial-left {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #17191d;
}

.share-box__trial-left_much {
  color: #45bda9;
}

.share-box__trial-left_little {
  color: #fa8e8e;
}

.share-box__trial-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.7);
}
.form__plan-price-cards {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  column-gap: 20px;
}

.madeby-box {
  padding: 8px 0 27px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.7);
  transition: all ease 0.3s;
}

.madeby__url {
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  transition: all ease 0.3s;
  border-bottom: rgba(0, 0, 0, 0.4) 1px solid;
}

.colors {
  height: 100%;
  padding-left: 40%;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  min-height: 100vh;
}

.madeby_mob {
  display: none;
}

@media screen and (max-width: 1240px) {
  .header__url-domain {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 1130px) {
  .share__url {
    font-size: 12px;
    line-height: 16px;
  }
}

@media screen and (max-width: 1000px) {
  .header__url-domain {
    max-width: 600px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .result {
    flex-direction: column;
  }
  .info__header {
    padding: 0 30px 20px;
    width: 100%;
  }
  .info {
    width: 100%;
    height: auto;
    min-height: auto;
    padding: 75px 0 20px;
    position: relative;
    align-items: center;
  }
  .info-without-banner {
    padding-top: 20px;
  }
  .colors {
    padding-left: 0;
    width: 100%;
    height: auto;
    min-height: 100%;
  }
  .header__link {
    margin-bottom: 2px;
  }
  .image-box {
    width: 50%;
  }
  .madeby-box {
    display: none;
  }
  .madeby_mob {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7);
    transition: all ease 0.3s;
    display: block;
    text-align: center;
    padding: 40px 0 30px;
  }
  .share__url {
    font-size: 14px;
    line-height: 19px;
  }
}
@media screen and (max-width: 768px) {
  .image-box {
    width: 70%;
  }
  .browser__body {
    min-height: 200px;
  }
}

@media screen and (max-width: 600px) {
  .info__header{
    padding: 0 16px 16px;
  }
}

@media screen and (max-width: 450px) {
  .share__url {
    font-size: 12px;
    line-height: 16px;
  }
  .info__header_unlogged {
    flex-direction: column;
    align-items: flex-start;
  }
  .info__header-get-ext {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 375px) {
  .image-box {
    width: 90%;
  }
}
</style>
