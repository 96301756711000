<template>
  <section class="help-page">
    <Header />
    <Main />
    <Footer />
  </section>
</template>

<script>
import Header from "@/components/landing/Header";
import Main from "@/components/help/Main";
import Footer from "@/components/landing/Footer";

export default {
  components: {
    Header,
    Main,
    Footer,
  },
};
</script>
<style scoped>

* {
  color: #3d3d3d;
}

.help-page {
  width: 100%;
  box-sizing: border-box;
}

</style>
