<template>
  <li class="btn-prime">
    <a class="btn-prime__link btn-prime__link-primary"><slot></slot></a>
  </li>
</template>

<script>
export default {
  name: "BtnHeaderPrime",
};
</script>

<style>
.btn-prime {
  margin-left: 20px;
  list-style: none;
  list-style-image: none;
}

.btn-prime__link {
  height: 50px;
  display: flex;
  align-items: center;
  background: #3080f2;
  border-radius: 10px;
  padding: 0 25px;
  white-space: nowrap;
  cursor: pointer;
}

.btn-prime__link:hover {
  background: #0e65e1;
}

.btn-prime__link-active {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.075);
  cursor: default;
}
/*
.btn-prime__link-primary {
  background: #3080f2;
  height: 44px;
  padding: 0 25px;
} */

/* .btn-prime__link-primary:hover {
  background: #0e65e1;
} */

@media screen and (max-width: 1024px) {
  .btn-prime {
    margin: 20px 10px;
  }
}

@media screen and (max-width: 700px) {
  .btn-prime {
    margin: 0 0 20px 0;
  }
}
</style>
