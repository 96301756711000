<template>
  <section class="section-overview">
    <div class="wrapper">
      <h2 class="section-overview__heading section-heading">
        Create, mix, share, download
      </h2>
      <p class="section-overview__subheading section-subheading">
        Shareable permalink, Sketch and Adobe Swatch downloadables and more
      </p>
			<div class="section-overview__image section-image">
				<img src="../../images/overview.png" alt="Section overview">
			</div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.section-overview {
  padding: 120px 0 120px;
  position: relative;
  background: #fff;
  color: #3d3d3d;
}

@media screen and (max-width: 830px) {
  .section-overview {
    padding: 54px 0 60px;
  }
}

.section-overview img {
  width: 1140px;
}

.wrapper {
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
}

@media screen and (max-width: 1360px) {
  .wrapper {
    width: 100%;
  }
}

.section-heading {
  font-weight: 600;
  font-size: 48px;
  line-height: 62px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #17191D;
}

.section-subheading {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #17191D;
  margin: 16px 0 0;
  padding: 0;
}

.section-image {
  padding: 0 2%;
  margin-top: 40px;
  text-align: center;
}
@media screen and (max-width: 830px) {
  .section-image {
    margin-top: 10vw;
  }
}
.section-image img {
  max-width: 100%;
}

@media screen and (max-width: 600px) {
  .wrapper {
    padding: 0 16px;
  }
  .section-image {
    margin-top: 10vw;
  }
  .section-heading {
    font-size: 36px;
    line-height: 44px;
  }
  .section-subheading {
    margin-top: 12px;
  }
  .section-image {
    padding: 0;
  }
}

</style>
