<template>
  <div class="table">
    <div class="col col_property">
      <div class="row" v-for="(item, index) in propertyArray" :class="{'row_odd row_odd_left': index % 2}">
        <span class="row__text">{{ item }}</span>
      </div>
    </div>
    <div class="col col_our">
      <h3 class="col__title">Site Palette</h3>
      <div class="row" v-for="(_, index) in propertyArray" :class="{'row_odd': index % 2}">
        <img src="@/images/check-ours.svg" alt="">
      </div>
    </div>
    <div class="col col_competitior">
      <h3 class="col__title">{{ competitorTitle }}</h3>
      <div class="row" v-for="(_, index) in propertyArray" :class="{'row_odd row_odd_right': index % 2}">
        <img src="@/images/check-comp.svg" alt="" v-if="hasPropertyArray.includes(index)" >
        <img src="@/images/cross.svg" alt="" v-else>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    competitorTitle: {
      type: String,
      required: true
    }
  },
  data() {
    const propertyArray = [
      'Generate palettes from any browser page',
      'Browser extension',
      'Unlimited generations',
      'Copy colors by click',
      'Color cube palette',
      'Material design palette',
      'Median cut palette',
      'Download SVG',
      'Download Sketch Template',
      'Download Abode Swatch',
      'Open palette in Google Palette',
      'Open palette in Coolors',
      'Profile with all created palettes',
      'Free trial',
      'Sharing your palettes'
    ];
    const hasPropertyArray = [1, 2, 3, 5, 13]; //check for competitor col otherwise cross
    return {
      propertyArray,
      hasPropertyArray
    };
  },
};
</script>

<style scoped>
.table {
  border-radius: 12px;
  border: 1px solid rgba(41, 44, 46, 0.20);
  padding: 48px;
  width: 100%;
  max-width: 916px;
  display: flex;
}
.col {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.col_property {
  width: 54%;
  padding-top: 61px;
}
.col_our {
  width: 20%;
  border: 1px solid #6159F7;
  border-radius: 12px;
}
.col_competitior {
  padding-top: 21px;
  width: 26%;
}
.row {
  padding: 6px 32px;
  height: 42px;
  display: flex;
}
.row img {
  margin: 0 auto;
}
.row_odd {
  background: rgba(118, 147, 252, 0.10);
}
.row_odd_left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.row_odd_right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.col__title {
  color: #17191D;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 18px;
  text-align: center;
}
.row__text {
  color: #17191D;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 3px 0 5px;
}
@media screen and (max-width: 900px) {
  .table {
    padding: 24px;
  }
}
@media screen and (max-width: 768px) {
  .table {
    width: 614px;
    max-width: 614px;
  }
  .col_property {
    width: 184px;
    padding-right: 16px;
  }
  .col_our {
    width: 184px;
  }
  .col_competitior {
    width: 200px;
  }
  .row {
    padding: 6px 0;
    height: 56px;
    align-items: center;
  }
  .row_odd {
    background: transparent;
  }
  .row__text {
    margin: 0;
  }
}
@media screen and (max-width: 646px) {
  .table {
    align-self: flex-start;
  }
}
</style>
