<template>
  <main class="main padding">
    <div class="container">
      <div class="main__content">
        <div class="main__text-block">
          <h1 class="main__title">
            Get the essential colours from any website
          </h1>
          <h2 class="main__subtitle">
            A browser extension to generate comprehensive palettes. A must-have tool for designers and frontend developers. Precise colour scheme extraction.
          </h2>
          <GetExtBtn/>
          <p class="main__extensions-disclamer">
              Free trial is available for new members.
          </p>
        </div>
        <div class="main__cards-box">
          <Cards />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Cards from "@/components/landing/Cards";
import GetExtBtn from "@/components/GetExtensionButton";

export default {
  components: {
    Cards, GetExtBtn
  }
};

</script>

<style scoped>
* {
  color: #3d3d3d;
}

.main {
	background-color: #F5F8FA;
}

.padding {
  padding: 0 20px;
}

.container {
  width: 100%;
  max-width: 1224px;
  margin: 0 auto;
}

.main__content {
  display: flex;
  justify-content: space-between;
  padding: 92px 0 110px;
}

.main__text-block {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.main__title {
  font-weight: 600;
  font-size: 60px;
  line-height: 62px;
  color: #17191D;
  letter-spacing: -0.02em;
  max-width: 550px;
  margin-bottom: 24px;
}

.main__subtitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #17191D;
  max-width: 460px;
  margin-bottom: 24px;
}

.main__extensions-disclamer{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
  margin: 14px 0 0;
  padding: 0;
}

.main__cards-box {
  width: 50%;
  padding-top: 100px;
}

@media screen and (max-width: 900px) {
  .main__content {
    flex-direction: column;
    align-items: center;
		padding: 68px 0 150px;
  }

  .main__text-block {
    width: 80%;
    align-items: center;
    min-width: 335px;
  }

  .main__title {
    width: 100%;
    text-align: center;
  }

  .main__subtitle {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }

  .main__cards-box {
    width: 80%;
    padding-top: 160px;
  }
}

@media screen and (max-width: 600px) {
  .main__content {
    flex-direction: column;
    align-items: center;
		padding: 75px 0 360px;
  }
  .main__title{
    min-width: 343px;
    font-size: 48px;
    line-height: 50px;
  }
  .padding {
    padding: 0 16px;
  }
  .main__text-block {
    width: auto;
  }
  .main__subtitle {
    max-width: 343px;
    min-width: 343px;
  }

  .main__extension{
    width: 343px;
  }
  .main__subtitle {
    margin-bottom: 28px;
  }
  .main__cards-box {
    padding-top: 146px;
  }

}
</style>
