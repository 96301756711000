<template>
  <div class="cards">
    <div @click="newCards" :class="cards[0]">
      <h3 class="card__title">Median cut</h3>
      <p class="card__description">
        Clusters similar colors in 3D model, sort them by volume and returns
        base colors for each block
      </p>
      <ul class="card__colors">
        <li class="card__color">
          <div class="card__color-block" style="background: #644ca2"></div>
          <p class="card__color-value">#644ca2</p>
        </li>
        <li class="card__color">
          <div class="card__color-block" style="background: #3e77e9"></div>
          <p class="card__color-value">#3e77e9</p>
        </li>
        <li class="card__color">
          <div class="card__color-block" style="background: #ff5952"></div>
          <p class="card__color-value">#ff5952</p>
        </li>
        <li class="card__color">
          <div class="card__color-block" style="background: #4fc775"></div>
          <p class="card__color-value">#4fc775</p>
        </li>
        <li class="card__color">
          <div class="card__color-block" style="background: #ffd656"></div>
          <p class="card__color-value">#ffd656</p>
        </li>
      </ul>
    </div>
    <div @click="newCards" :class="cards[1]">
      <h3 class="card__title">Material design</h3>
      <p class="card__description">Based on Palette class from Android SDK</p>
      <ul class="card__colors">
        <li class="card__color">
          <div class="card__color-block" style="background: #1e306e"></div>
          <p class="card__color-value">#1e306e</p>
        </li>
        <li class="card__color">
          <div class="card__color-block" style="background: #563672"></div>
          <p class="card__color-value">#563672</p>
        </li>
        <li class="card__color">
          <div class="card__color-block" style="background: #8e3c77"></div>
          <p class="card__color-value">#8e3c77</p>
        </li>
        <li class="card__color">
          <div class="card__color-block" style="background: #c6427b"></div>
          <p class="card__color-value">#c6427b</p>
        </li>
        <li class="card__color">
          <div class="card__color-block" style="background: #fe4880"></div>
          <p class="card__color-value">#fe4880</p>
        </li>
      </ul>
    </div>
    <div @click="newCards" :class="cards[2]">
      <h3 class="card__title">Color cube</h3>
      <p class="card__description">
        Builds 3D histogram grid, searches for local maxima of the hit count
      </p>
      <ul class="card__colors">
        <li class="card__color">
          <div class="card__color-block" style="background: #3d3d3d"></div>
          <p class="card__color-value">#3d3d3d</p>
        </li>
        <li class="card__color">
          <div class="card__color-block" style="background: #6059f7"></div>
          <p class="card__color-value">#6059f7</p>
        </li>
        <li class="card__color">
          <div class="card__color-block" style="background: #6592fe"></div>
          <p class="card__color-value">#6592fe</p>
        </li>
        <li class="card__color">
          <div class="card__color-block" style="background: #36c3fe"></div>
          <p class="card__color-value">#36c3fe</p>
        </li>
        <li class="card__color">
          <div class="card__color-block" style="background: #8c61ff"></div>
          <p class="card__color-value">#8c61ff</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cards: ["card card-1", "card card-2", "card card-3"],
    };
  },
  mounted() {
    setInterval(this.newCards, 4000)
  },
  methods: {
    newCards: function () {
      let newCards = [].concat(this.cards.splice(1), this.cards.slice(0, 1));
      this.cards = newCards;
    },
  },
};
</script>

<style scoped>
.cards {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 100px;
}

.card {
  width: 100%;
  padding: 20px 30px 30px;
  position: absolute;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 5px;
  transition: top ease 0.5s, opacity ease 0.5s, -webkit-transform ease 0.5s;
  transition: top ease 0.5s, opacity ease 0.5s, transform ease 0.5s;
  transition: top ease 0.5s, opacity ease 0.5s, transform ease 0.5s,
    -webkit-transform ease 0.5s;
  opacity: 0;
  top: 100px;
  cursor: pointer;
}

.card-1 {
  z-index: 1;
  opacity: 1;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  top: -115px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
}

.card-2 {
  z-index: 2;
  opacity: 1;
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
  top: -59px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
}

.card-3 {
  z-index: 3;
  opacity: 1;
  top: 0;
}

.card__title {
  font-size: 20px;
  font-weight: bold;
  color: #3d3d3d;
}

.card__description {
  color: #9aabb5;
  font-size: 14px;
  line-height: 1.3em;
  margin: 10px 0;
  width: 100%;
}

.card__colors {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.card__color {
  text-align: center;
  width: calc(100% / 5.5);
}

.card__color-block {
  width: 100%;
  height: 60px;
  border-radius: 5px;
}

.card__color-value {
  font-weight: bold;
  color: #3d3d3d;
  font-size: 13px;
  margin-top: 7px;
}

@media screen and (max-width: 600px) {
  .card {
    padding: 20px 20px 10px;
  }

  .card__colors {
    flex-wrap: wrap;
    column-gap: 10px;
    justify-content: center;
  }

  .card__color {
    width: calc(100% / 2.2);
    min-width: 70px;
    margin-bottom: 10px;
  }
}
</style>
