<template>
    <div class="palettes__card">
        <div class="card__header">
            <h2 class="card__name">{{palette.title}}</h2>
            <p class="card__date">Created {{palette.createdDate}}</p>
            <button class="card__button-delete" @click.stop="openDeletePalette"></button>
        </div>
        <div class="cadr__palettes">
            <div class="palette cube">
                <div class="color" :style="{'background-color':color.hex}" v-for="color in palette.colors.cube"></div>
            </div>
            <div class="palette median">
              <div class="color" :style="{'background-color':color.hex}" v-for="color in palette.colors.thief"></div>
            </div>
            <div class="palette material">
              <div class="color" :style="{'background-color':color.hex}" v-for="color in palette.colors.vibrant"></div>
            </div>
        </div>
        <DeletePalette @close="closeDeletePalette" @confirmDelete="confirmDeletePalette(palette._id)" :isDeletePaletteOpen="isDeletePaletteOpen"/>
    </div>
</template>

<script>
import DeletePalette from "@/components/DeletePalette";

export default {
    props: {
      palette: {
        type: Object,
        default: () => ({})
      },
    },
    components: {
      DeletePalette
    },
    data() {
      return {
        isDeletePaletteOpen: false,
      };
    },
    methods: {
      openDeletePalette: function (event) {
        this.isDeletePaletteOpen = true;
      },
      closeDeletePalette: function (status) {
        this.isDeletePaletteOpen = status;
      },
      confirmDeletePalette: function (paletteId) {
        this.$store.dispatch("deletePalette", paletteId);
      },
    },
  }
</script>

<style scoped>

* {
	background: #fefefe;
}

.palettes__card {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  box-sizing: border-box;
  padding: 24px;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid #f7f7f7;
  position: relative;
  max-width: 392px;
}

.card__header {
  display: flex;
  align-items: baseline;
}

.card__name {
  color: #3080F2;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  padding: 0;
  margin-right: auto;
  text-overflow: ellipsis;
  max-width: 190px;
  overflow:hidden;
  white-space:nowrap;
}

.card__date {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  margin-right: 15px;
  min-width: 84px;
}

.card__button-delete {
  width: 12px;
  height: 14px;
  background-image: url("../images/trash-palette.svg");
  background-position: center center;
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  align-self: center;
}

.card__button-delete:hover {
  cursor: pointer;
  opacity: 0.8;
}

.cadr__palettes {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.palette {
  display: flex;
}

.color {
  width: 100%;
  height: 42px;
}

@media screen and (max-width: 768px) {
  .card__date {
    margin-right: 0;
  }
}

</style>
