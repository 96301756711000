<template>
  <main class="signin" v-if="$store.getters.checkingLoginML">
      <Loader />
  </main>
  <main class="signin" v-else>
    <header class="header padding">
      <div class="container">
        <div class="header__content">
          <router-link to="/" class="header__logo">
            <img src="@/images/logo-white.svg" />
          </router-link>

          <nav class="header__navigation"></nav>
        </div>
      </div>
    </header>

    <section class="sign-in">
      <div class="sign-in__content">
        <h2>Sign in</h2>
        <form @submit.prevent="login" class="form">
          <fieldset class="form__field">
            <p class="form__text">
              Type your email to login/register<br />and get a magic code.
            </p>
          </fieldset>
          <fieldset class="form__field">
            <input
              v-model="email"
              type="email"
              @blur="isEmailTouched = true"
              class="form__input"
              :class="{ error: isEmailError }"
              name="email"
              placeholder="Email"
              required="true"
              aria-required="true"
            />
            <label id="email-error" class="email-error">
              <p v-if="isEmailError">Wrong e-mail address</p>
            </label>
          </fieldset>
          <fieldset class="form__field form__fild-action">
            <button type="submit"
             :disabled="!isEmailValid || isMLPending"
             class="btn btn--primary"
             :class="{ 'button--loading': isMLPending }"
             ><span class="btn__text" :class="{ btn__text_hide: isMLPending }">Proceed</span></button>
          </fieldset>
          <p class="form__subtitle">By proceeding you signify you’ve read and agreed to our <router-link to="/tos" class="form__subtitle_tos">Terms of service</router-link></p>
        </form>
      </div>
    </section>
    <footer class="footer padding">
      <div class="container">
        <div class="footer__content">
          <div class="footer__request">
            <a
              class="footer__link-request"
              href="mailto:support@palette.site?Subject=PRO"
              >✍🏻 &#160;Request a feature</a
            >
          </div>

          <div class="footer__madeby">
            Made in New Zealand 🇳🇿 at
            <a class="footer__link" href="https://sliday.com" target="_blank"
              >Sliday</a
            >
          </div>

          <div class="footer__terms">
            <router-link to="/privacy" class="footer__link">Privacy policy</router-link>
            <router-link to="/tos" class="footer__link">Terms of service</router-link>
          </div>
        </div>
      </div>
    </footer>
  </main>
  <Background />
</template>

<script>
import Background from "@/components/Background";
import Loader from "@/components/Loader";
import store from '@/store'

const emailCheckRegex = /.+@.+\..+/i;

import { ref } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    Background,
    Loader,
  },
  data() {
    return {
      email: null,
      isEmailTouched: false,
      isMLPending: false
    };
  },
  methods: {
    login() {
      this.isMLPending = true;
      store.dispatch("login", { email: this.email });
    }
  },
  computed: {
    isEmailValid() {
      return emailCheckRegex.test(this.email);
    },
    isEmailError() {
      return !this.isEmailValid && this.isEmailTouched;
    }
  },
  setup() {
  },
  beforeRouteEnter(to, from, next){
    store.dispatch("checkLoginML");
    next();
  },
};
</script>

<style scoped>
.padding {
  padding: 0 20px;
}

.container {
  width: 100%;
  max-width: 1224px;
  margin: 0 auto;
}

/* HEADER */
.header {
}

.header__content {
  box-sizing: border-box;
  padding: 34px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  width: 172px;
  height: 24px;
  z-index: 2;
  position: relative;
}

.header__logo:hover {
  opacity: 0.8;
}

.header__logo img {
  width: 100%;
}

.header__navigation {
  display: flex;
  column-gap: 28px;
}

/* SIGNIN */

.signin {
  min-height: 100vh;
  background-color: #3350c4;
  display: flex;
  flex-direction: column;
}

.sign-in__content {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sign-in {
  padding: 150px 0;
  transition: transform ease 0.5s, -webkit-transform ease 0.5s;
  display: block;
  /* position: relative;
  z-index: 2; */
}

h2 {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0.75px;
  line-height: 38px;
  max-width: 720px;
  text-align: center;
  margin: 0 auto;
}

h3 {
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 20px;
  opacity: 0.6;
  margin: 0 auto;
  margin-top: 15px;
  text-align: center;
  max-width: 570px;
  font-weight: normal;
}

a:hover {
  opacity: 0.6;
}

form {
  width: 310px;
  margin: 0 auto;
  margin-top: 28px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

form::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  visibility: hidden;
  background: transparent;
  opacity: 0;
  transition: all ease 0.3s;
}

.form__field {
  margin-bottom: 25px;
  position: relative;
  transition: all ease 0.3s;
  border: 0;
  width: 100%;
}

.form__field:last-child {
  margin-top: 50px;
}

.form__input {
  width: 100%;
  display: block;
  height: 47px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.09);
  border: 1px solid transparent;
  padding: 0 20px;
  color: #fff;
  letter-spacing: 0.25px;
  transition: all ease 0.3s;
  font-size: 14px;
  outline: none;
  font-family: "GeneralSans", Helvetica, Arial
}

.form__input::-webkit-input-placeholder {
    color:    rgba(144, 155, 203, 1);
}
.form__input:-moz-placeholder {
   color:    rgba(144, 155, 203, 1);
   opacity:  1;
}
.form__input::-moz-placeholder {
   color:    rgba(144, 155, 203, 1);
   opacity:  1;
}
.form__input:-ms-input-placeholder {
   color:    rgba(144, 155, 203, 1);
}
.form__input::-ms-input-placeholder {
   color:    rgba(144, 155, 203, 1);
}

.form__input::placeholder {
   color:    rgba(144, 155, 203, 1);
}

/* input:-webkit-autofill {
//     -webkit-box-shadow: 0 0 0px 1000px white inset;
//     -webkit-text-fill-color: red !important;
// }*/

.form__subtitle{
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #909BCB;
  max-width: 310px;
  text-align: center;
}
.form__subtitle_tos{
  color: #60A1FF;
  cursor: pointer;
}

.error {
  background: rgba(255, 61, 61, 0.3);
  color: #ff638f;
}

.email-error {
  color: #ff638f;
  font-size: 10px;
  letter-spacing: 0.25px;
  bottom: -15px;
  padding-left: 20px;
  position: absolute;
}

.form__note {
  text-align: center;
  font-size: 12px;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 17px;
  margin: 5px auto;
  width: 100%;
}

.form__fild-action {
  text-align: center;
}

.btn {
  outline: none;
  border: none;
  display: inline-flex;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  transition: all ease 0.3s;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
  height: 50px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 8%);
  width: 207px;
  margin: 18px 0 25px;
}

.btn__text {
  font-size: 13px;
  line-height: 44px;
  color: #fff;
  letter-spacing: 1.35px;
  font-weight: 600;
  font-family: "GeneralSans", Helvetica, Arial
}

.btn__text_hide {
  visibility: hidden;
  opacity: 0;
}

.btn:hover {
  opacity: 0.85;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
}

.btn--primary {
  background: #3080f2;
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.form__text {
  text-align: center;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
}

/* FOOTER */
.footer {
  margin-top: auto;
}

.footer__content {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 30px 0;
}

.footer__content div {
  width: 33.33%;
}

.footer__request {
  font-size: 14px;
  letter-spacing: 0.25px;
  position: relative;
  z-index: 3;
}

.footer__link-request {
  opacity: 0.5;
  position: relative;
  z-index: 3;
}

.footer__link-request:hover {
  opacity: 1;
}

.footer__madeby {
  text-align: center;
  font-size: 13px;
  letter-spacing: 0.25px;
  opacity: 0.5;
  transition: all ease 0.3s;
  position: relative;
  z-index: 3;
}

.footer__madeby:hover {
  opacity: 1;
}

.footer__link {
  position: relative;
  z-index: 3;
}

.footer__link:hover {
  opacity: 1;
}

.footer__terms {
  text-align: right;
}

.footer__terms a {
  font-size: 14px;
  opacity: 0.5;
  margin-left: 25px;
}

@media screen and (max-width: 750px) {
  .footer__content {
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }

  .footer__request,
  .footer__madeby,
  .footer__terms {
    text-align: center;
  }

  .footer__content div {
    width: 100%;
  }

  .footer__terms a:first-child {
    margin-left: 0;
  }
}
</style>
