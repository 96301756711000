<template>
  <section class="profile">
    <header class="header padding">
      <div class="container">
        <div class="header__content">
          <router-link to="/" class="header__logo">
            <img src="@/images/logo-black.svg" />
          </router-link>
          <nav class="header__navigation">
            <router-link
              to="/palettes"
              class="header__link"
              v-show="!user.expired"
              >My palettes</router-link
            >
          </nav>
        </div>
      </div>
    </header>
    <main class="form padding">
      <h1 class="profile__title">My profile</h1>
      <div class="container">
        <div class="form__content">
          <div class="form__alert" v-show="isPlanUpgraded">
            <img class="alert__icon" src="@/images/upgradeOK.svg" />
            <p class="alert__text">You successfully upgraded your plan</p>
          </div>
          <div class="form__alert form__alert_exp" v-show="isUpgradeCanceled">
            <img class="alert__icon" src="@/images/upgradeFail.svg" />
            <p class="alert__text">Payment failed! Try again.</p>
          </div>
          <div class="form__plan" :class="{ form__plan_exp: user.expired }">
            <div class="form__plan-info">
              <p class="current-plan__title">My current plan</p>
              <div class="form__plan__col">
                <p
                  class="current-plan__name"
                  :class="{ 'current-plan__name_exp': user.expired }"
                >
                  {{ currentPlanName }}
                </p>
                <p class="time-left">{{ user.planTimeLeft }}</p>
                <p
                  class="time-left"
                  v-show="
                    !user.expired && !isUserPlanLifetime && !isUserPlanTrial
                  "
                >
                  {{ user.nextPay }}
                </p>
              </div>
            </div>
            <div class="form__plan-price-cards" v-show="!isUserPlanLifetime">
              <PriceCard
                v-for="info in priceInfo"
                :priceInfo="info"
                :selectedSubs="selectedSubs"
                @set-selected-sub="setSelectedSub"
                :key="info.type"
              />
            </div>
          </div>
          <div class="form__email">
            <form class="email-form" action="">
              <label class="email__label" for="mail">Email</label>
              <input
                class="email__input"
                :class="{ email__input_exp: user.expired }"
                type="email"
                placeholder="user@mail.com"
                id="mail"
                name="user_email"
                readonly
                :value="user.email"
              />
            </form>
          </div>
        </div>
      </div>
    </main>
    <footer class="footer padding">
      <div class="container">
        <div class="footer__content">
          <a class="btn" @click="logout">Log out</a>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
import PriceCard from "@/components/PriceCard";
import store from "@/store";
import { useStore } from "vuex";
import queryString from "query-string";
import mainApi from "../utils/MainApi";
import {
  getInfoAboutCurrentPlan,
  getInfoAboutPlanTimeLeft,
  getInfoAboutNextPay,
} from "../utils/utils";
import { event } from "vue-gtag";

export default {
  components: {
    PriceCard,
  },
  data() {
    let isUserPlanTrial = store.state.user.plan.toLowerCase() === "trial";
    let isUserPlanLifetime = store.state.user.plan.toLowerCase() === "lifetime";
    let priceSubsBtnText =
      store.state.user.expired || isUserPlanTrial ? "Subscribe" : "Manage";
    let isSafariAgent = store.state.browser === "safari";
    return {
      isUserPlanTrial,
      isSafariAgent,
      isUserPlanLifetime,
      isPaymentSending: false,
      btnUpgradeText: "Upgrade",
      priceInfo: [
        {
          title: "Subscription",
          price: "$2.50/mo",
          bill: "billed annually",
          btnText: priceSubsBtnText,
          type: "sub",
        },
        {
          title: "Lifetime",
          price: "$99",
          bill: "billed once",
          btnText: "Purchase",
          type: "life",
        },
      ],
      selectedSubs: "sub",
      isSafariAgent,
    };
  },
  setup() {
    const store = useStore();
    const logout = () => {
      store.dispatch("logout");
    };

    let user = store.getters.user;
    const planInfoDisplayNames = {
      Monthly: "1 month",
      Annual: "1 year",
    };

    let currentPlanName =
      user.planInfo in planInfoDisplayNames
        ? `${user.planInfo} / ${planInfoDisplayNames[user.planInfo]}`
        : user.planInfo;
    let isPlanUpgraded = Boolean(queryString.parse(location.search).upgraded);
    let isUpgradeCanceled = Boolean(
      queryString.parse(location.search).canceled
    );

    if (isPlanUpgraded) {
      if (user.planInfo == "Monthly") {
        event("purchase_subscription", {
          event_category: "purchase",
          event_label: "Purchase Subscription",
          value: 3,
          currency: "USD",
        });
      } else if (user.planInfo == "Annual") {
        event("purchase_annual", {
          event_category: "purchase",
          event_label: "Purchase Annual",
          value: 2.50,
          currency: "USD",
        });
      } else if (user.planInfo == "Lifetime") {
        event("purchase_lifetime", {
          event_category: "purchase",
          event_label: "Purchase Lifetime",
          value: 99,
          currency: "USD",
        });
      }
    }
    return {
      logout,
      isPlanUpgraded,
      isUpgradeCanceled,
      user,
      currentPlanName,
    };
  },

  methods: {
    setSelectedSub(val) {
      this.selectedSubs = val;
    },
  },
  beforeRouteEnter(to, from, next) {
    mainApi.getUser(store.getters.userToken).then((data) => {
      data.user.planInfo = getInfoAboutCurrentPlan(data.user, "long");
      if (data.user.plan !== "lifetime") {
        data.user.nextPay = getInfoAboutNextPay(data.user);

        if (data.user.expired) {
          data.user.planTimeLeft =
            data.user.plan.toLowerCase() === "trial"
              ? "Trial has expired"
              : "Subscription has expired";
        } else {
          data.user.planTimeLeft = getInfoAboutPlanTimeLeft(data.user);
        }
      } else {
        data.user.nextPay = "";
        data.user.planTimeLeft = getInfoAboutPlanTimeLeft(data.user);
      }
      store.dispatch("setUser", data.user);
      next();
    });
  },
};
</script>

<style scoped>
* {
  color: #292c2e;
}

.profile {
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.padding {
  padding: 0 20px;
}

.container {
  width: 100%;
  max-width: 1224px;
  margin: 0 auto;
}

/* HEADER */

.header__content {
  box-sizing: border-box;
  padding: 34px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  width: 172px;
  height: 24px;
}

.header__logo:hover {
  opacity: 0.8;
}

.header__logo img {
  width: 100%;
}

.header__navigation {
  display: flex;
  column-gap: 28px;
}

.header__link {
  font-weight: 600;
  color: #3080f2;
  font-size: 16px;
  line-height: 22px;
  box-sizing: border-box;
  padding: 0 10px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__link:hover {
  opacity: 0.8;
}

/* FORM */

.profile__title {
  font-weight: 600;
  font-size: 36px;
  line-height: 38px;
  color: #000000;
  padding: 15px 0 0;
  margin: 0 auto 32px;
  max-width: 1224px;
}

.form__content {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form__alert {
  padding: 10px 20px;
  background-color: #45bda9;
  border-radius: 10px;
  display: flex;
  column-gap: 10px;
  align-items: center;
  margin-bottom: 15px;
}
.form__alert_exp {
  background-color: rgba(252, 118, 118, 0.8);
}

.alert__icon {
  width: 14px;
  height: 14px;
  /*border-radius: 50%;*/
}

.alert__text {
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  font-weight: 900;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}

.form__plan {
  width: 400px;
  margin-bottom: 30px;
  padding: 20px 20px;
  background-color: rgba(69, 189, 169, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.form__plan-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
}

.form__plan-box {
  padding: 20px 20px;
  background-color: rgba(69, 189, 169, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  /*height: 129px;*/
}

.form__plan__col {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  align-self: stretch;
  row-gap: 4px;
  min-width: 170px;
}
.form__plan-price-cards {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  column-gap: 20px;
}
.current-plan__info {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: 4px;
}

.form__plan_exp {
  background: rgba(128, 128, 128, 0.05);
}

.current-plan {
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
}

.current-plan__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #292c2e;
  opacity: 0.9;
}

.current-plan__name {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.5);
}

.current-plan__name_exp {
  color: rgba(41, 44, 46, 0.5);
}

.time-left {
  text-align: right;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}
.time-left {
  color: rgba(41, 44, 46, 0.6);
}

.time-left:last-of-type {
  margin-top: 12px;
}

.time-left_link {
  color: #3080f2;
  cursor: pointer;
}

.btn {
  color: #3080f2;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  transition: all ease 0.3s;
}
.time-left__padding {
  padding-bottom: 2px;
}
.time-left__exp {
  max-width: 103px;
  text-align: left;
}

.btn-sent-pay {
  padding: 8px 16px;
  width: 98px;
  height: 38px;
  background: #7693fc;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  cursor: pointer;
  position: relative;
}

.btn,
.btn-sent-pay:hover {
  opacity: 0.8;
}

.email-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.email__label {
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: rgba(41, 44, 46, 0.5);
  margin-bottom: 4px;
}

.email__input {
  font-family: "GeneralSans", Helvetica, Arial;
  padding: 14px;
  border: 1px solid rgba(41, 44, 46, 0.3);
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: rgba(41, 44, 46, 0.5);
  outline: none;
}
.email__input_exp {
  color: rgba(41, 44, 46, 0.5);
}

.footer {
  margin-top: auto;
}

.footer__content {
  padding-bottom: 79px;
}

.btn-logout {
  display: flex;
  align-items: center;
  column-gap: 10px;
  border: 2px solid #7693fc;
  box-sizing: border-box;
  width: fit-content;
  padding: 13px 25px;
  border-radius: 10px;
  transition: all ease 0.3s;
  cursor: pointer;
}

.logout__icon {
  width: 24px;
  height: 24px;
}

.logout__icon svg path {
  transition: all ease 0.3s;
}

.logout__text {
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  color: #7693fc;
  transition: all ease 0.3s;
}

.btn-logout:hover {
  border-color: #6159f7;
}

.btn-logout:hover .logout__text {
  color: #6159f7;
}

.btn-logout:hover svg path {
  fill: #6159f7;
  cursor: pointer;
}

@media screen and (max-width: 540px) {
  .form__content {
    width: 100%;
  }
}

@media screen and (max-width: 440px) {
  .padding {
    padding: 0 10px;
  }
  .form__plan {
    padding: 10px;
    width: 336px;
  }
  .form__plan-price-cards {
    column-gap: 8px;
  }
  .form__plan__col {
    min-width: 154px;
  }
  .email-form {
    width: 336px;
  }
}

@media screen and (max-width: 350px) {
  .current-plan {
    display: flex;
    flex-direction: column;
  }
  .current-plan__title {
    text-align: center;
    margin-bottom: 13px;
  }
  .current-plan__name {
    text-align: center;
  }
  .time-left {
    text-align: center;
  }
  .padding {
    padding: 0 8px;
  }
}
</style>
