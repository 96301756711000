<template>
  <section class="how-it-works padding">
    <div class="container">
      <div class="how-it-works__content">
        <h2 class="how-it-works__title">
          How it works?
        </h2>
        <p class="how-it-works__subtitle">
          Unless shared, everything is done locally, safe and secure
        </p>
        <div class="section-block">
          <ul class="cards">
            <li class="card">
              <h3 class="card-title">
                Install the extension
              </h3>
              <div class="card-image">
                <img src="../../images/howit-first-new.svg" alt="install-exstension"/>
              </div>
            </li>
            <li class="card">
              <h3 class="card-title">Click the icon in a browser</h3>
              <div class="card-image">
                <img src="../../images/howit-second.png" alt="click-exstension"/>
              </div>
            </li>
            <li class="card">
              <h3 class="card-title">Generate your first palette</h3>
              <div class="card-image">
                <img src="../../images/howit-third.png" alt="generate-palette"/>
              </div>
            </li>
            <li class="card">
              <h3 class="card-title">See all your palettes in History</h3>
              <div class="card-image">
                <img src="../../images/howit-fourth.png" alt="all-palettes"/>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
* {
  color: #3d3d3d;
  background: #fefefe;
}

.padding {
  padding: 0 20px;
}

.container {
  width: 100%;
  max-width: 1224px;
  margin: 0 auto;
}

.how-it-works__content {
  padding: 120px 0 0px;
}

.how-it-works__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 62px;
  letter-spacing: -0.02em;
  color: #17191D;
  text-align: center;
  margin-bottom: 16px;
}

.how-it-works__subtitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #17191D;
}

.section-block {
  margin: 48px auto 0;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cards {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  max-width: 1198px;
}

.card {
  width: calc(100% / 4.3);
  max-width: 262px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
}

.card-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #17191D;
  max-width: 233px;
  margin: 0;
  padding: 0;
}

.card-image {
  width: 100%;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}

.card-image_chrome{
  width: 51.62px;
  height: 51.62px;
}

.card img {
  width: 100%;
}

@media screen and (max-width: 1070px) {
  .section-block {
    max-width: 574px;
  }
  .cards {
    width: 100%;
    flex-wrap: wrap;
    row-gap: 12px;
  }
  .card {
    width: 262px;
  }
}

@media screen and (max-width: 800px) {
  .cards {
    min-width: 335px;
    flex-wrap: wrap;
    margin: 0 auto;
    column-gap: 30px;
    justify-content: center;
  }
}


@media screen and (max-width: 600px) {
  .padding {
    padding: 0 16px;
  }
  .how-it-works__content {
    padding: 50px 0 20px;
  }
  .how-it-works__title {
    font-size: 36px;
    line-height: 44px;
  }
  .cards {
    flex-direction: column;
    max-width: 400px;
  }
  .card-image {
    width: 166px;
    min-width: 166px;
    height: 166px;
    margin-bottom: 0;
  }
  .card {
    width: 100%;
    max-width: 100%;
    flex-direction: row-reverse;
    column-gap: 24px;
    justify-content: start;
    background: transparent;
  }
  .card:nth-of-type(2n) {
    flex-direction: row;
  }
  .card-title {
    text-align: left;
  }
}
</style>
