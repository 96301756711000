<template>
  <div
    class="price-card-section"
    :class="{ 'price-card-section_active': this.isActive }"
  >
    <h3 class="price-card__title">{{ priceInfo.title }}</h3>
    <div class="price-card__info">
      <p class="price-card__text" v-show="priceInfo.title === 'Subscription'">
        from
      </p>
      <p class="price-card__price">{{ priceInfo.price }}</p>
      <p class="price-card__text price-card__text_bill">{{ priceInfo.bill }}</p>
      <button
        class="price-card__btn-buy"
        :class="{
          'price-card__btn-buy_active': isBtnBuyActive,
          'btn-loading': isPaymentSending,
          'btn-loading_subs-active': isLoadingPurple,
        }"
        @click="sendPayment"
      >
        {{ btnBuyText }}
      </button>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  props: {
    priceInfo: {
      type: Object,
      default: () => ({}),
    },

    selectedSubs: {
      type: String,
      default: "",
    },
  },
  data() {
    const store = useStore();
    const user = store.getters.user;
    const isUserPlanValid = user && ["month", "year"].includes(user.plan);
    let isLoadingPurple = isUserPlanValid && this.priceInfo.btnText === "Manage";

    return {
      isPaymentSending: false,
      btnBuyText: this.priceInfo.btnText,
      user,
      isLoadingPurple,
    };
  },

  methods: {
    sendPayment(event) {
      this.$emit("set-selected-sub", this.priceInfo.type);
      this.isPaymentSending = true;
      this.btnBuyText = "";
      if (this.priceInfo.btnText === "Manage") {
        this.$store.dispatch("changePlan");
      } else {
        this.$store.dispatch("sendPayment", this.priceInfo.title === "Lifetime");
      }
    },
  },
  computed: {
    isActive() {
      return this.priceInfo.type === this.selectedSubs;
    },
    isBtnBuyActive: function () {
      const res = this.user
        ? ["month", "year"].includes(this.user.plan)
          ? this.priceInfo.btnText === "Purchase"
          : this.isActive
        : false;
      return res;
    },
  },
};
</script>

<style scoped>
.price-card-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 170px;
  height: 180px;
  background: #ffffff;
  border: 1px solid rgba(41, 44, 46, 0.1);
  border-radius: 10px;
  padding: 12px 16px 16px;
}

.price-card-section_active {
  /*outline: 4px solid #7693FC;*/
  box-shadow: 0 0 0 4px #7693fc;
  border: 1px solid #fff;
}
.price-card__title {
  align-self: flex-start;
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #17191d;
}
.price-card__info {
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.price-card__text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #17191d;
  padding: 0;
  margin: 0;
}

.price-card__text_bill {
  color: rgba(0, 0, 0, 0.5);
}

.price-card__price {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #17191d;
  margin: 2px 0 4px;
  padding: 0;
}

.price-card__btn-buy {
  font-family: "GeneralSans", Helvetica, Arial;
  margin-top: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #7693fc;
  background: #ffffff;
  border: 2px solid #7693fc;
  border-radius: 10px;
  width: 138px;
  height: 38px;
  cursor: pointer;
  position: relative;
}
.price-card__btn-buy:hover {
  opacity: 0.8;
}
.price-card__btn-buy_active {
  color: #ffffff;
  background: #7693fc;
}

.btn-loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 2px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.btn-loading_subs-active::after {
  border-top-color: #7693fc;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

@media screen and (max-width: 440px) {
  .price-card-section {
    padding: 12px 8px 12px;
    width: 154px;
  }
  .price-card__btn-buy {
    width: 134px;
  }
}
</style>
