<template>
  <a class="main__extension" :href="btnExtRef" target="_blank">
    <img class="main__extension-img" :src="btnExtIcon" alt="browser icon">
    <p p class="main__extension-text">{{btnExtText}}</p>
  </a>
</template>

<script>
import store from '@/store'

import iconChrome from '@/images/icon-chrome.svg'
import iconFF from '@/images/icon-firefox.svg'
import iconSafari from '@/images/icon-safari.svg'
import iconMS from '@/images/icon-ms.svg'
import iconOpera from '@/images/icon-opera.svg'
import iconBrave from '@/images/icon-brave.svg'

export default {
  data() {
    let btnExtText = 'Get for Chrome';
    let btnExtIcon = iconChrome;
    let btnExtRef = "https://chrome.google.com/webstore/detail/site-palette/pekhihjiehdafocefoimckjpbkegknoh";

    switch (store.state.browser) {
    case 'safari':
      btnExtIcon = iconSafari;
      btnExtText = 'Get for Safari';
      btnExtRef = "https://apps.apple.com/us/app/site-palette/id6451384418?mt=12"
      break;
    case 'firefox':
      btnExtIcon = iconFF;
      btnExtText = 'Get for Firefox';
      btnExtRef = "https://addons.mozilla.org/addon/site-palette"
      break;
    case 'edge-chromium':
      btnExtIcon = iconMS;
      btnExtText = 'Get for MS Edge';
      break;
    case 'brave':
      btnExtIcon = iconBrave;
      btnExtText = 'Get for Brave';
      break;
    case 'opera':
      btnExtIcon = iconOpera;
      btnExtText = 'Get for Opera';
      break;
    default:
      break;
    }
    return {
      btnExtText,
      btnExtIcon,
      btnExtRef
    }
  }
};
</script>

<style scoped>
.main__extension {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  width: auto;
  height: 56px;
  background-color: #6159F7;
  border-radius: 10px;
}

.main__extension-img {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
.main__extension-text {
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #FFFFFF;
}
.main__extension:hover{
  opacity: 0.8;
}

@media screen and (max-width: 600px) {
  .main__extension{
    width: 100%;
    max-width: 343px;
  }
}
</style>
