export function getInfoAboutCurrentPlan(initialUser){
  const plan = initialUser.plan.toLowerCase();
  let planName = "";
  switch (plan) {
    case "trial":
      planName = "Trial / 24 hours"
      break;
    case "month":
      planName = `Monthly`;
      break;
    case "year":
      planName = `Annual`;
      break;
    case "lifetime":
      planName = "Lifetime";
      break;
    default:
      break;
  }
  return `${planName}`;
}
function msToTime(ms, form) {
  let hours = (ms / (1000 * 60 * 60));
  let minutes = (hours - Math.floor(hours))*60;
  let days = (ms / (1000 * 60 * 60 * 24));
  if (Math.floor(days) > 0){
    return `${Math.round(days)} days left`;
  }
  if (form === 'short') {
    return `${Math.floor(hours)} h ${Math.round(minutes)} m left`;
  }
  return `${Math.floor(hours)} hours ${Math.round(minutes)} minutes left`;
}
export function getInfoAboutPlanTimeLeft(initialUser, form = 'long'){
  if (initialUser.plan.toLowerCase() === "lifetime") {
    return "∞ days left";
  }
  else {
    const planEnded = new Date(initialUser.plan_end);
    const dateNow = new Date();
    return msToTime(planEnded-dateNow, form);
  }
}
export function getInfoAboutNextPay(initialUser){
  return `Next payment: ${new Date(initialUser.plan_end).toLocaleDateString('en-GB')}`;
}