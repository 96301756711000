<template>
  <footer class="footer">
    <div class="wrapper">
      <div class="footer__left">
        <router-link to="/" class="footer__logo" aria-label="link to main page">
          <img src="@/images/logo-white.svg" alt="logo" />
        </router-link>
        <div class="footer__copyright">
          <span>Made with ❤ by <a class="footer__copyright-link" href="https://karmabot.chat/" target="_blank">Karma</a> team</span>
          <span> © {{ currentYear }} All rights reserved.</span>
        </div>
      </div>
      <nav class="footer__navigation">
        <div class="footer__navigation-col">
          <router-link to="/comparison" class="footer__link">Why Site Palette?</router-link>
          <router-link to="/vs-color" class="footer__link">Site Palette vs. Color Palette</router-link>
          <router-link to="/vs-extract" class="footer__link">Site Palette vs. Extract Colors DevTool</router-link>
          <router-link to="/vs-website" class="footer__link">Site Palette vs. Website Color Palette</router-link>
        </div>
        <div class="footer__navigation-col">
          <router-link to="/help" class="footer__link">Help</router-link>
          <router-link to="/privacy" class="footer__link">Privacy policy</router-link>
          <router-link to="/tos" class="footer__link">Terms of service</router-link>
        </div>
      </nav>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
};
</script>

<style scoped>

.footer {
  background: #202629;
  position: relative;
}
.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding: 56px 20px;
  width: 100%;
  max-width: 1224px;
  margin: 0 auto;
  height: 248px;
}

.footer__left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer__logo {
  width: 198px;
  height: 28px;
}

.footer__navigation{
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 24px;
  align-items: flex-start;
}

.footer__navigation-col{
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 24px;
  flex-wrap: wrap;
  align-items: flex-start;
}

.footer__link{
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  min-width: 288px;
}

.footer__logo:hover, .footer__link:hover {
  opacity: 0.8;
}

.footer__copyright {
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.footer__copyright-link{
  border-bottom: 1px solid #fff;
}

.footer__copyright-link:hover {
  opacity: 0.8
}

@media screen and (max-width: 900px) {
  .wrapper {
    flex-direction: column-reverse;
    height: auto;
    gap: 32px;
  }
  .footer__logo {
    margin-bottom: 32px;
  }
  .footer__navigation{
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .wrapper {
    padding: 48px 16px;
  }
}
</style>
