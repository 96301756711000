<template>
  <section class="popup" >
    <div class="container">
      <div class="popup__content">
        <h2>Terms and Conditions</h2>
        <p>Last updated: May 30, 2018</p>
        <p>
          These Terms and Conditions ("Terms", "Terms and Conditions") govern
          your relationship with <strong>https://palette.site</strong> website
          (the "Service") operated by Sliday Limited ("us", "we", or "our").
        </p>
        <p>
          Please read these Terms and Conditions carefully before using the
          Service.
        </p>
        <p>
          Your access to and use of the Service is conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users and others who access or use the Service.
        </p>
        <p>
          By accessing or using the Service you agree to be bound by these
          Terms. If you disagree with any part of the terms then you may not
          access the Service.
        </p>
        <h3>Subscriptions</h3>
        <p>
          Some parts of the Service are billed on a subscription basis
          ("Subscription(s)"). You will be billed in advance on a recurring and
          periodic basis ("Billing Cycle"). Billing cycles are set on a monthly
          basis.
        </p>
        <p>
          At the end of each Billing Cycle, your Subscription will automatically
          renew under the exact same conditions unless you cancel it or Sliday
          Limited cancels it. You may cancel your Subscription renewal either
          through your online account management page or by contacting Sliday
          Limited customer support team.
        </p>
        <p>
          A valid payment method, including credit card, is required to process
          the payment for your Subscription. You shall provide Sliday Limited
          with accurate and complete billing information including full name,
          address, state, zip code, telephone number, and a valid payment method
          information. By submitting such payment information, you automatically
          authorize Sliday Limited to charge all Subscription fees incurred
          through your account to any such payment instruments.
        </p>
        <p>
          Should automatic billing fail to occur for any reason, Sliday Limited
          will issue an electronic invoice indicating that you must proceed
          manually, within a certain deadline date, with the full payment
          corresponding to the billing period as indicated on the invoice.
        </p>
        <h3>Fee Changes</h3>
        <p>
          Sliday Limited, in its sole discretion and at any time, may modify the
          Subscription fees for the Subscriptions. Any Subscription fee change
          will become effective at the end of the then-current Billing Cycle.
        </p>
        <p>
          Sliday Limited will provide you with a reasonable prior notice of any
          change in Subscription fees to give you an opportunity to terminate
          your Subscription before such change becomes effective.
        </p>
        <p>
          Your continued use of the Service after the Subscription fee change
          comes into effect constitutes your agreement to pay the modified
          Subscription fee amount.
        </p>
        <h3>Refunds</h3>
        <p>
          Except when required by law, paid Subscription fees are
          non-refundable.
        </p>
        <h3>Accounts</h3>
        <p>
          When you create an account with us, you must provide us information
          that is accurate, complete, and current at all times. Failure to do so
          constitutes a breach of the Terms, which may result in immediate
          termination of your account on our Service.
        </p>
        <p>
          You are responsible for safeguarding the password that you use to
          access the Service and for any activities or actions under your
          password, whether your password is with our Service or a third-party
          service.
        </p>
        <p>
          You agree not to disclose your password to any third party. You must
          notify us immediately upon becoming aware of any breach of security or
          unauthorized use of your account.
        </p>
        <h3>Intellectual Property</h3>
        <p>
          The Service and its original content, features and functionality are
          and will remain the exclusive property of Sliday Limited and its
          licensors. The Service is protected by copyright, trademark, and other
          laws of both the New Zealand and foreign countries. Our trademarks and
          trade dress may not be used in connection with any product or service
          without the prior written consent of Sliday Limited.
        </p>
        <h3>Links To Other Web Sites</h3>
        <p>
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by Sliday Limited.
        </p>
        <p>
          Sliday Limited has no control over, and assumes no responsibility for,
          the content, privacy policies, or practices of any third party web
          sites or services. You further acknowledge and agree that Sliday
          Limited shall not be responsible or liable, directly or indirectly,
          for any damage or loss caused or alleged to be caused by or in
          connection with use of or reliance on any such content, goods or
          services available on or through any such web sites or services.
        </p>
        <p>
          We strongly advise you to read the terms and conditions and privacy
          policies of any third-party web sites or services that you visit.
        </p>
        <h3>Termination</h3>
        <p>
          We may terminate or suspend your account immediately, without prior
          notice or liability, for any reason whatsoever, including without
          limitation if you breach the Terms.
        </p>
        <p>
          Upon termination, your right to use the Service will immediately
          cease. If you wish to terminate your account, you may simply
          discontinue using the Service.
        </p>
        <h3>Limitation Of Liability</h3>
        <p>
          In no event shall Sliday Limited, nor its directors, employees,
          partners, agents, suppliers, or affiliates, be liable for any
          indirect, incidental, special, consequential or punitive damages,
          including without limitation, loss of profits, data, use, goodwill, or
          other intangible losses, resulting from (i) your access to or use of
          or inability to access or use the Service; (ii) any conduct or content
          of any third party on the Service; (iii) any content obtained from the
          Service; and (iv) unauthorized access, use or alteration of your
          transmissions or content, whether based on warranty, contract, tort
          (including negligence) or any other legal theory, whether or not we
          have been informed of the possibility of such damage, and even if a
          remedy set forth herein is found to have failed of its essential
          purpose.
        </p>
        <h3>Disclaimer</h3>
        <p>
          Your use of the Service is at your sole risk. The Service is provided
          on an "AS IS" and "AS AVAILABLE" basis. The Service is provided
          without warranties of any kind, whether express or implied, including,
          but not limited to, implied warranties of merchantability, fitness for
          a particular purpose, non-infringement or course of performance.
        </p>
        <p>
          Sliday Limited its subsidiaries, affiliates, and its licensors do not
          warrant that a) the Service will function uninterrupted, secure or
          available at any particular time or location; b) any errors or defects
          will be corrected; c) the Service is free of viruses or other harmful
          components; or d) the results of using the Service will meet your
          requirements.
        </p>
        <h3>Governing Law</h3>
        <p>
          These Terms shall be governed and construed in accordance with the
          laws of New Zealand, without regard to its conflict of law provisions.
        </p>
        <p>
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights. If any provision of these
          Terms is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Service, and
          supersede and replace any prior agreements we might have between us
          regarding the Service.
        </p>
        <h3>Changes</h3>
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material we will try to
          provide at least 30 days notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion.
        </p>
        <p>
          By continuing to access or use our Service after those revisions
          become effective, you agree to be bound by the revised terms. If you
          do not agree to the new terms, please stop using the Service.
        </p>
        <h3>Contact Us</h3>
        <p>If you have any questions about these Terms, please contact us.</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
};
</script>

<style scoped>
.popup {
  background-color: #F5F8FA;
  padding: 72px 20px;
}

.popup__content {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  color: black;
  position: relative;
  max-width: 1224px;
}

.popup__content h2 {
  font-weight: 600;
  font-size: 60px;
  line-height: 62px;
  color: #17191D;
  text-align: center;
  margin-bottom: 56px;
}

.popup__content h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #17191D;
  margin-top: 25px;
  margin-bottom: 10px;
}

.popup__content p {
  margin: 15px 0;
  font-size: 16px;
  line-height: 24px;
  color: #3b4b50;
  letter-spacing: 0.1px;
}

.popup__content p a {
  color: #3080F2;
}

.popup__content p a:hover {
  opacity: 0.8;
}

.popup__content ul {
  margin: 15px 0;
  padding-left: 10px;
}

.popup__content ul li {
  margin: 10px 0;
  letter-spacing: 0.1px;
  color: #3b4b50;
  font-size: 16px;
  line-height: 24px;
}

.popup__content ul li:before {
    content: "•";
    display: inline-block;
    margin-right: 5px;
}

@media screen and (max-width: 768px) {
  .popup__content h2 {
    font-size: 48px;
    line-height: 50px;
    margin-bottom: 40px;
  }
}
</style>