<template>
  <section class="not-found-page">
    <Header />
    <div class="not-found-page__main">
        <div class="not-found-page__content">
            <div class="not-found-page__img"></div>
            <p class="not-found-page__code">404</p>
            <p class="not-found-page__text">The page you are looking for does not seem to exist</p>
            <router-link to="/"  class="not-found-page__back">
                <p class="not-found-page__homepage">Continue to the Homepage</p>
                <div class="not-found-page__arrow"></div>
            </router-link>
        </div>
    </div>
    <Footer />
  </section>
</template>

<script>
import Header from "@/components/landing/Header";
import Footer from "@/components/landing/Footer";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>
<style scoped>

* {
  color: #3d3d3d;
}

.not-found-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  min-height: 100vh;
}

.not-found-page__main {
  background-color: #F5F8FA;
  flex: 1;
}

.not-found-page__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 100px auto 72px;
}
.not-found-page__img {
  background-image: url("../images/404.svg");
  width: 192px;
  height: 120px;
  margin-bottom: 32px;
}

.not-found-page__code {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -1.45px;
    line-height: 1.2em;
    color: #3d3d3d;
    margin: 0 0 12px;
    padding: 0;
}

.not-found-page__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #3d3d3d;
    margin: 0 0 32px;
    padding: 0;
    text-align: center
}
.not-found-page__back{
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.not-found-page__homepage {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3080F2;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  box-sizing: border-box;
}

.not-found-page__arrow {
    width: 22px;
    height: 22px;
    background-image: url("../images/arrow-left.svg");
}

</style>
