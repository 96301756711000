<template>
    <div v-show="isDeletePaletteOpen" @click.stop class="form__alert">
        <p class="alert__text">Delete this palette?</p>
        <div class="form__btns" v-show="!isDeleting">
            <button
                class="alert__text alert__text_btn"
                @click.stop="confirmDeletePalette"
            >Yes</button>
            <button
                class="alert__text alert__text_btn"
                @click.stop="closeDeletePalette"
            >No</button>
        </div>
        <div class="form__loader" v-show="isDeleting"></div>
    </div>
</template>

<script>
export default {
    props: {
        isDeletePaletteOpen: {
        type: Boolean,
        required: true,
        },
    },
    data() {
        const isDeleting = false;
        return {
        isDeleting: false,
      };
    },
    methods: {
        confirmDeletePalette: function () {
            this.$emit("confirmDelete");
            this.isDeleting = true;
        },
        closeDeletePalette: function () {
            this.status = false;
            this.$emit("close", this.status);
        },
    },
};
</script>

<style scoped>
.form__alert {
    padding: 10px 20px;
    background: #FA8E8E;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95.4%;
    position: absolute;
    left: 9px;
    top: 18px;
    cursor: auto;
}
.form__btns {
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 30px;
}

.form__btns_hide{
    display: none;
}

.alert__text {
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  font-weight: 800;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}
.alert__text_btn {
  font-family: "GeneralSans", Helvetica,Arial;
  color: #B70202;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form__loader::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  right: 52px;
  bottom: 0;
  margin: auto;
  border: 2px solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
  box-sizing: border-box;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
</style>

