<template>
  <section class="product-hunt">
    <h2 class="title">Like this product? Upvote!</h2>
		<a class="logo" href="https://www.producthunt.com/posts/site-palette-2-0" target="_blanck">
			Upvote on Product Hunt
		</a>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.product-hunt {
  padding: 36px 0 36px;
  background: #EF6C5C;
  color: #3d3d3d;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.title {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  margin: 0 32px 0 0;
}

.logo {
  padding: 8px 0 10px 52px;
  width: 283px;
  height: 48px;
  border: 2px solid #FFFFFF;
  border-radius: 10px;
  background-image: url('../../images/prod-hunt.svg');
  background-position: left 24px top 16px;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #FFFFFF;
}
.logo:hover {
  opacity: 0.8;
}

@media screen and (max-width: 800px) {
  .product-hunt {
    flex-direction: column;
  }
  .title {
    font-size: 36px;
    line-height: 42px;
    margin: 0 0 16px 0;
  }
}

@media screen and (max-width: 600px) {
  .title {
    font-size: 24px;
    line-height: 40px;
  }
}

</style>
