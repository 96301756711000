class MainApi {
  constructor(mainUrl) {
      this._mainUrl = mainUrl;
  }

  _getResponseData(res) {
    if (res.ok) {
     return res.json();
    }
    const err = new Error(`Ошибка: ${res.status}`);
    err.statusCode = res.status;
    return Promise.reject(err);
  }
  
  login(token){
      return fetch(`${this._mainUrl}/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
      .then(this._getResponseData);
  };

  logout(token){
    return fetch(`${this._mainUrl}/users/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
};

  getUser(token){
      return fetch(`${this._mainUrl}/users/me`, {
        headers: {
          authorization: `Bearer ${token}`
        }
      })
      .then(this._getResponseData);
  }

  getPalettes(token){
    return fetch(`${this._mainUrl}/palettes`, {
      headers: {
        authorization: `Bearer ${token}`
      }
    })
    .then(this._getResponseData);
  }

  getPaletteById(paletteId){
    return fetch(`${this._mainUrl}/palettes/${paletteId}`)
    .then(this._getResponseData);
  }

  deletePalette(token, paletteId){
    return fetch(`${this._mainUrl}/palettes/${paletteId}`, {
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(this._getResponseData);
  }

  sendPayment(token, isLifetime){
    return fetch(`${this._mainUrl}/payment/billing${isLifetime ? '?isLifetime=true' : ''}`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(this._getResponseData);
  }
  changePlan(token){
    return fetch(`${this._mainUrl}/payment/upgrade`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(this._getResponseData);
  }
}
const mainApi = new MainApi(process.env.VUE_APP_SERVER_URL);

export default mainApi;