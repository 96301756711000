<template>
  <main class="main">
    <div class="main__content">
      <h1 class="main__title">
        Effortless Firefox integration
      </h1>
      <div class="main__info">
        <div class="main__info-sections">
          <div class="main__info-section">
            <p class="main__info-text">
              Please be sure permission "Access your data for all web sites" is on in the extension's settings. To find it, follow the instructions below:
            </p>
            <ol class="main__info-list">
              <li class="main__info-item">Open your browser and type "about:debugging" in the address bar;</li>
              <li class="main__info-item">Click on the name of the extension to open settings;</li>
              <li class="main__info-item">Under "Permissions", you will see a list of requested permissions for the extension;</li>
              <li class="main__info-item">Check that the permission for the "Access your data on all web sites" is enabled. If it is disabled, toggle it to enabled.</li>
            </ol>
            <p class="main__info-text">
              This will allow the extension to access your data on all web sites, which is necessary for it to work properly.
            </p>
            <img src="../../images/install-ff.gif" alt="intalling in firefox" class="main__info-img"/>
          </div>
        </div>
      </div>

    </div>
  </main>
</template>

<script>
export default {
};
</script>

<style scoped>
* {
  color: #3d3d3d;
}

.main {
	background-color: #F5F8FA;
  padding: 72px 20px;
  overflow: visible;
}

.main__content {
  display: flex;
  place-content: center;
  align-items: center;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  gap: 56px;
  height: min-content;
  max-width: 1224px;
  overflow: visible;
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.main__title {
  font-weight: 600;
  font-size: 60px;
  line-height: 62px;
  color: #17191D;
  text-align: center;
}

.main__info{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex: 0 0 auto;
  flex-flow: row wrap;
  height: min-content;
  overflow: visible;
  padding: 0px;
  position: relative;
  width: 100%;
}

.main__info-sections{
  display: flex;
  place-content: flex-start;
  align-items: flex-start;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  gap: 40px;
  height: min-content;
  max-width: 66%;
  overflow: visible;
  padding: 0px;
  position: relative;
  width: 100%;
}

.main__info-section{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  height: min-content;
  overflow: visible;
  padding: 0px;
  position: relative;
  scroll-margin-top: 20px;
  width: 100%;
}

.main__info-title{
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #17191D;
}

.main__info-text{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #17191D;
  margin: 0;
  padding: 0;
}

.main__info-list{
  margin: 5px  0 5px 20px;
  display: flex;
  flex-direction: column;
}

.main__info-item{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #17191D;
  margin: 0;
  padding: 0;
}

.main__info-img{
  flex: 0 0 auto;
  height: auto;
  position: relative;
  width: 100%;
}

.main__info-table-of-cont{
  place-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  gap: 12px;
  height: min-content;
  max-width: 28%;
  overflow: visible;
  padding: 0px;
  position: sticky;
  top: 20px;
  width: 28%;
  will-change: transform;
  z-index: 1;
}

.main__info-name-title{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #3080F2;
  margin: 0;
  padding: 0;
}
.main__info-name-title:hover{
  opacity: 0.8;
}
.main__info-name-title a{
  color: #3080F2;
}

@media screen and (max-width: 768px) {
  .main__content {
    gap: 40px;
  }
  .main__title {
    font-size: 48px;
    line-height: 50px;
  }
  .main__info{
    flex-direction: column;
    row-gap: 32px;
  }
  .main__info-table-of-cont{
    width: 100%;
    max-width: 100%;
    top: 0;
    position: relative;
  }
  .main__info-sections{
    max-width: none;
  }
}
</style>