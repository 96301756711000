<template>
  <div class="background-wrapper">
    <kinesis-container class="background">
      <kinesis-element class="confetti-2" type="translate" :strength="100" />
      <kinesis-element class="confetti" type="translate" :strength="50" />
      <kinesis-element class="confetti-3" type="translate" :strength="20" />
    </kinesis-container>
  </div>
</template>

<script>
import { KinesisContainer, KinesisElement } from "vue-kinesis";
export default {
  components: {
    KinesisContainer,
    KinesisElement,
  },
};
</script>

<style scoped>

.background-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
}

.background {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  opacity: 0.8;
  top: 0;
  left: 0;
  overflow: hidden;
}

.confetti {
  width: 120%;
  height: 120%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(../images/par2.png);
  position: absolute;
  opacity: 0.05;
}

.confetti-2 {
  width: 120%;
  height: 120%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(../images/par1.png);
  position: absolute;
  opacity: 0.05;
}

.confetti-3 {
  width: 120%;
  height: 120%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(../images/par3.png);
  position: absolute;
  opacity: 0.5;
  top: -20px;
}
</style>
