<template>
  <a class="footer__pro" :class="{'footer__pro_hide': !$store.state.isBannerShow}" target="_blank" href="https://karmabot.chat/">
    🔥 Using chats at work? Try <span class="footer__pro_bold">Karma bot</span>
    <button class="footer__pro-close" @click="closeBanner"></button>
  </a>
</template>

<script>
import store from '@/store'

export default {
    data() {
      return {
      }
    },
    methods: {
      closeBanner: function (evt) {
        evt.preventDefault();
        store.dispatch('setBannerHide', true);
      },
    },
  }
</script>

<style scoped>
.footer__pro {
  width: 300px;
  flex: none;
  height: 58px;
  background-image: url('../images/img-banner.svg');
  background-color: transparent;
  border-radius: 10px;
  text-decoration: none;
  display: block;
  transition: all ease 0.3s;
  position: relative;
  display: flex;
  align-items: start;
  padding: 15.42px 0 0 12px;
  text-align: center;
  justify-content: start;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  position: absolute;
  bottom: 57px;
  left: 50%;
  margin-left: -150px;
}

.footer__pro_hide{
  display: none;
}

.footer__pro-close{
  width: 16px;
  height: 16px;
  background-image: url('../images/close-banner.svg');
  position: absolute;
  top: 4px;
  right: 4px;
  border: none;
  background-color: transparent;
  background-size: cover;
  cursor: pointer;
}

.footer__pro:hover {
  transform: translateY(2px) !important;
}
@media screen and (max-height: 700px) {
  .footer__pro {
    position: relative;
    bottom: auto;
    margin: 0;
    left: auto;
  }
}

@media screen and (max-width: 1000px) {
  .footer__pro {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    background: linear-gradient(112.38deg, #3350C4 16.16%, #8C61FF 108.49%);
    margin: 0;
    border-radius: 0;
    opacity: 1 !important;
    visibility: visible !important;
    transform: translateX(0) !important;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  .footer__pro::after {
    display: none;
  }
}

.footer__pro_bold {
  text-transform: uppercase;
  display: inline-block;
  margin-left: 4px;
  font-weight: bold;
  background: transparent;
}
</style>
