<template>
  <li class="btn">
    <a class="btn__link"><slot></slot></a>
  </li>
</template>

<script>
export default {
  name: "BtnHeader",
};
</script>

<style scoped>
.btn {
  margin-left: 20px;
  list-style: none;
  list-style-image: none;
}

.btn__link {
  height: 38px;
  display: flex;
  align-items: center;
  background: transparent;
  border-radius: 10px;
  padding: 0 22px;
  white-space: nowrap;
  cursor: pointer;
}

.btn__link:hover {
  background: rgba(255, 255, 255, 0.075);
}

.btn__link-active {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.075);
  cursor: default;
}

@media screen and (max-width: 1024px) {
  .btn {
    margin: 20px 10px;
  }
}

@media screen and (max-width: 700px) {
  .btn {
    margin: 0 0 20px 0;
  }
}
</style>
