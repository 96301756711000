<template>
  <main class="main">
    <div class="main__content">
      <h1 class="main__title">
        Help
      </h1>
      <div class="main__info">
        <div class="main__info-table-of-cont">
          <h3 class="main__info-name-title">
            <a href="#FAQ1">Logging in to Site Palette</a>
          </h3>
          <h3 class="main__info-name-title">
            <a href="#FAQ2">Simplifying your design work with Site Palette</a>
          </h3>
          <h3 class="main__info-name-title">
            <a href="#FAQ3">Exploring Site Palette's features</a>
          </h3>
          <h3 class="main__info-name-title">
            <a href="#FAQ4">Site Palette's pricing</a>
          </h3>
          <h3 class="main__info-name-title">
            <a href="#FAQ5">Site Palette refund policy</a>
          </h3>
        </div>
        <div class="main__info-sections">
          <div class="main__info-section" id="FAQ1">
            <h2 class="main__info-title">
              Logging in to Site Palette
            </h2>
            <p class="main__info-text">
              Sign in to Site Palette, simply using the magic link on our website.
              Once logged in, head to the Chrome Web Store to add the Site Palette extension.
              Once installed, the Site Palette icon will appear in your browser's toolbar for easy access.
            </p>
            <img src="../../images/FAQ-1.gif" alt="Logging" class="main__info-img"/>
          </div>
          <div class="main__info-section" id="FAQ2">
            <h2 class="main__info-title">
              Simplifying your design work with Site Palette
            </h2>
            <p class="main__info-text">
              Suppose you want to extract a color scheme from the Apple store.
              To do this, just click the Site Palette extension and choose "Create Palette."
              Site Palette will take website screenshots, analyze the colors, and generate a palette for you.
            </p>
            <img src="../../images/FAQ-2.gif" alt="Logging" class="main__info-img"/>
          </div>
          <div class="main__info-section" id="FAQ3">
            <h2 class="main__info-title">
              Exploring Site Palette's features
            </h2>
            <p class="main__info-text">
              After obtaining your palette, you can utilize it in various ways.
              You can copy colors, download an SVG file, or use it as a design reference.
              Additionally, you can share the palette with your colleagues.
            </p>
            <img src="../../images/FAQ-3.gif" alt="Logging" class="main__info-img"/>
          </div>
          <div class="main__info-section" id="FAQ4">
            <h2 class="main__info-title">
              Site Palette's pricing
            </h2>
            <p class="main__info-text">
              We offer a free 1-day trial, which provides ample time to explore Site Palette's features.
              If you're satisfied with what you see (and we're confident you will be!), you can purchase a
              subscription for just $3 per month or $30 per year.
              This is a small investment that will significantly simplify your design work!
            </p>
          </div>
          <div class="main__info-section" id="FAQ5">
            <h2 class="main__info-title">
              Site Palette refund policy
            </h2>
            <p class="main__info-text">
              Please note we do not offer refunds for any type of Site Palette subscription.
              Therefore, we encourage you to carefully consider your purchase before committing to a subscription.
              If you have any questions, please contact us at <a class="main__info-link" href="mailto:support@palette.site">support@palette.site</a>.
            </p>
          </div>
        </div>
      </div>

    </div>
  </main>
</template>

<script>
export default {
};
</script>

<style scoped>
* {
  color: #3d3d3d;
}

.main {
	background-color: #F5F8FA;
  padding: 72px 20px;
  overflow: visible;
}

.main__content {
  display: flex;
  place-content: center;
  align-items: center;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  gap: 56px;
  height: min-content;
  max-width: 1224px;
  overflow: visible;
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.main__title {
  font-weight: 600;
  font-size: 60px;
  line-height: 62px;
  color: #17191D;
}

.main__info{
  display: flex;
  flex-direction: row;
  place-content: flex-start space-between;
  align-items: flex-start;
  flex: 0 0 auto;
  flex-flow: row wrap;
  height: min-content;
  overflow: visible;
  padding: 0px;
  position: relative;
  width: 100%;
}

.main__info-sections{
  display: flex;
  place-content: flex-start;
  align-items: flex-start;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  gap: 40px;
  height: min-content;
  max-width: 66%;
  overflow: visible;
  padding: 0px;
  position: relative;
  width: 100%;
}

.main__info-section{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  height: min-content;
  overflow: visible;
  padding: 0px;
  position: relative;
  scroll-margin-top: 20px;
  width: 100%;
}

.main__info-title{
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #17191D;
}

.main__info-text{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #17191D;
}

.main__info-link{
  cursor: pointer;
  color: #3080F2;
}

.main__info-img{
  flex: 0 0 auto;
  height: auto;
  position: relative;
  width: 100%;
}

.main__info-table-of-cont{
  place-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  gap: 12px;
  height: min-content;
  max-width: 28%;
  overflow: visible;
  padding: 0px;
  position: sticky;
  top: 20px;
  width: 28%;
  will-change: transform;
  z-index: 1;
}

.main__info-name-title{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #3080F2;
  margin: 0;
  padding: 0;
}
.main__info-name-title:hover{
  opacity: 0.8;
}
.main__info-name-title a{
  color: #3080F2;
}

@media screen and (max-width: 768px) {
  .main__content {
    gap: 40px;
  }
  .main__title {
    font-size: 48px;
    line-height: 50px;
  }
  .main__info{
    flex-direction: column;
    row-gap: 32px;
  }
  .main__info-table-of-cont{
    width: 100%;
    max-width: 100%;
    top: 0;
    position: relative;
  }
  .main__info-sections{
    max-width: none;
  }
}
</style>