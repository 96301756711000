<template>
  <div class="colors-section">
        <div class="colors-section__info">
          <div class="colors-section__heading">
            <h2 class="colors-section__title">{{card.name}}</h2>
            <div class="colors-section__summary">
              <div class="colors-section__summary__text">
                {{card.desc}}
              </div>
              <a
                class="colors-section__summary__github"
                :href="githubUrl"
                target="_blank"
              >
                <i class="icon-github"></i>
              </a>
            </div>
          </div>
          <div class="colors-section__list">
            <div class="color" data-color="#fdfdfd" v-for="(color, index) in card.colorArray" :class="{'is_five_in_row': (upHere && index > 4) }">
              <div
                class="color__brick js-color-copy is-light is-luminance"
                @click="colorCopy"
                :style="{'background-color':color.hex}">
              <p class="color__brick-title">Copied!</p>
              </div>
              <div class="color__value js-color-copy" @click="colorCopy">{{color.hex}}</div>
              <div class="color__name">{{color.name}}</div>
            </div>
          </div>
        </div>
        <ul class="colors-section__links">
          <li class="colors-section__link colors-section__link--sketch">
            <a
              class="download-link"
              :href="'https://api.palette.site/export/svg' + paletteUrl"
              target="_blank"
              >
              <div class="download-link__before"></div>
              SVG</a
            >
          </li>
          <li class="colors-section__link colors-section__link--sketch">
            <a
              class="download-link"
              :href="'https://api.palette.site/export/sketch' + colorsAndNames"
              target="_blank"
              >
              <div class="download-link__before"></div>
              Sketch Template</a
            >
          </li>
          <li class="colors-section__link colors-section__link--adobe">
            <a
              class="download-link"
              :href="'https://api.palette.site/export/ase' + colorsAndNames"
              target="_blank"
              >
              <div class="download-link__before"></div>
              Adobe Swatch</a
            >
          </li>
          <li class="colors-section__link colors-section__link--google" @mouseover="upHere = true" @mouseleave="upHere = false">
            <a
              class="external-link"
              :href="'https://artsexperiments.withgoogle.com/artpalette/colors/' + googleLink"
              target="_blank"
            >
            <div class="external-link__before"></div>
            Google Palette</a
            >
          </li>
          <li class="colors-section__link colors-section__link--coolors">
            <a
              class="external-link"
              :href="'https://coolors.co/app/' + coolorsLink"
              target="_blank"
              >
              <div class="external-link__before"></div>
              Coolors</a
            >
          </li>
        </ul>
      </div>
</template>

<script>
import tinycolor from "tinycolor2";

export default {
    props: {
      card: {
        type: Object,
        default: () => ({})
      },
      githubUrl: {
        type: String,
        default: () => ('')
      },
    },
    data() {
      const buildDownloadLink = function(input) {
        var colors = [], names = [];
        for (var i in input) {
          colors.push(input[i].hex.replace('#', ''));
          names.push(input[i].name);
        }
        return '?hexes=' + colors.join('-') + '&names=' + encodeURIComponent( names.join('-') );
      };
      const colorsAndNames = buildDownloadLink(this.card.colorArray)

      const buildCoolorsLink = function(input) {
        const res = [];
        for(let i = 0; i < input.length; i++) {
            res.push(input[i].hex.replace('#', ''))
        }
        return res.join('-');
      };
      const coolorsLink = buildCoolorsLink(this.card.colorArray)

      const buildGoogleLink = function(input) {
        const res = [];
        for(let i = 0; i < Math.min(5, input.length); i++) {
            res.push(input[i].hex.replace('#', ''))
        }
        return res.join('-');
      };
      const googleLink = buildGoogleLink(this.card.colorArray)

      const buildDownloadSVGLink = function(input, url) {
        var colors = [], names = [];
        for (var i in input) {
          colors.push(input[i].hex.replace('#', ''));
          names.push(input[i].name);
        }
        return '?hexes=' + colors.join('-') + '&names=' + encodeURIComponent( names.join('-') ) + '&url=' + encodeURIComponent(url);
      };

      const paletteUrl = buildDownloadSVGLink(this.card.colorArray, this.card.url);

      let upHere = false;

      return {
        colorsAndNames,
        coolorsLink,
        googleLink,
        paletteUrl,
        upHere
      }
    },
    methods: {
      colorCopy(event) {
        const colorValue = event.target.parentNode.querySelector('.color__value');
        const colorBrick = event.target.parentNode.querySelector('.color__brick');
        document.oncopy = function(event) {
          event.clipboardData.setData('text/plain', colorValue.textContent);
          event.preventDefault();
        };
        document.execCommand("copy", false, null);
        colorBrick.firstChild.classList.add("color__brick-title_show");
        colorBrick.classList.add("noHover");
        colorValue.style.color = tinycolor(colorValue.textContent).getLuminance() > 0.95 ? '#b3c1c7' : colorValue.textContent;
        setTimeout(() => {
          colorBrick.firstChild.classList.remove("color__brick-title_show");
          colorBrick.classList.remove("noHover");
          colorValue.style.color = "#3D3D3D";
        }, 500)
      }
    },
    mounted() {
    },

  }
</script>

<style scoped>

.colors-section {
  width: 100%;
  margin-bottom: 7px;
  background: #fff;
  height: 100%;
}

.colors-section__info {
  margin: 25px 40px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.colors-section__heading {
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
}

.colors-section__title {
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  color: #292c2e;
  margin: 0;
  padding: 0;
}

.colors-section__summary {
  margin-left: 25px;
  opacity: 1;
  transition: all ease 0.3s;
  display: flex;
  align-items: center;
}

.colors-section__summary__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3D3D3D;
}

.colors-section__summary__github {
  margin-left: 10px;
  opacity: 0.5;
  transition: all ease 0.3s;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.icon-github {
  background-image: url("../images/git-hub-icon.svg");
  width: 20px;
  height: 20px;
  display: inline-block;
}

.colors-section__summary__github:hover {
  opacity: 1;
}

.colors-section__list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.color {
  text-align: center;
  margin-bottom: 20px;
}

.color__value {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #3D3D3D;
  margin-top: 6px;
  cursor: pointer;
  transition: all ease 0.3s;
  display: inline-block;
}

.color__brick.is-luminance {
  border: 1px solid #e8ecef;
}
.color__brick {
  width: 130px;
  height: 80px;
  background: #ddd;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.is-copied {
  box-shadow: none;
  background: rgba(255, 255, 255, 0.5);
  cursor: default;
}

.color__brick-title{
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin: 0;
  padding: 0;
  color: #cccccc;
  display: none;
}
.color__brick-title_show{
  display: block;
}

.is-copied .color__brick::before {
  opacity: 0.3;
}
.is-copied .color__brick:hover::after {
  opacity: 0 !important;
}

.color__brick.is-light::before {
  color: #000;
}

.color__brick:hover::after {
  opacity: 1;
}

.color__brick::after {
  content: "";
  background-image: url("../images/copy-icon-dark.svg");
  width: 28px;
  height: 32px;
  position: absolute;
  background-size: cover;
  top: 50%;
  left: 50%;
  margin: -16px 0 0 -14px;
  opacity: 0;
  transition: all ease 0.3s;
}
.noHover{
    pointer-events: none;
}
.is_five_in_row{
  opacity: 0.2;
}

.colors-section__links {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  font-size: 13px;
  transition: opacity ease 0.15s, visibility ease 0.15s, padding ease 0.3s,
    height ease 0.15s;
  opacity: 1;
  padding: 24px 40px;
  gap: 35px;
  flex-direction: row;
  justify-content: start;
}

ol,
ul {
  list-style: none;
}

.colors-section__link {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.colors-section__link a {
  text-decoration: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #000000;
  text-transform: uppercase;
  transition: all ease 0.3s;
  display: inline-flex;
  -ms-align-items: center;
  align-items: center;
  background: transparent;
  border-radius: 3px;
  opacity: 0.7;
}

.colors-section__links a:hover {
  opacity: 0.5;
}

.download-link__before {
  background-image: url("../images/download-icon.svg");
  min-width: 12px;
  width: 12px;
  height: 15px;
  margin-right: 6px;
  opacity: 0.7;
  content: "";
  background-size: contain;
  background-position: left -1px center;
  background-repeat: no-repeat;
  transition: all ease 0.3s;
}

.external-link, .download-link{
  text-align: center;
}

.external-link__before {
  background-image: url("../images/external-link.svg");
  min-width: 13px;
  width: 13px;
  height: 13px;
  margin-right: 6px;
  content: "";
  background-size: cover;
  transition: all ease 0.3s;
}

.color__name {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3D3D3D;
}

@media screen and (max-width: 1212px) {
  .colors-section__links{
    column-gap: 20px;
  }
}
@media screen and (max-width: 1120px) {
  
  .colors-section__link a{
    font-size: 10px;
    line-height: 13.3px;
  }
}

@media screen and (max-width: 1000px) {  
  .colors-section__heading {
    justify-content: center;
    width: 100%;
  }
  .colors-section__summary{
    display: none;
  }

  .colors-section__links{
    justify-content: center;
    opacity: 1;
    padding-right: 30px;
    padding-left: 30px;
    margin: auto;
  }
  .colors-section__list {
    width: 90%;
    margin: 0 auto;
    justify-content: center;
  }
  .colors-section__info {
    margin: 25px 30px 0 30px;
  }
  .colors-section__link--sketch, .colors-section__link--adobe, .colors-section__link--google, .colors-section__link--coolors{
    max-width: none;
  }
}
@media screen and (max-width: 800px) {
  .colors-section__links{
    flex-wrap: wrap;
    gap: 20px;
    margin: auto;
  }
  .colors-section__link{
    margin-right: 0;
  }
}

@media screen and (max-width: 568px) {
  .colors-section__links{
    max-width: 450px;
  }
}

@media screen and (max-width: 410px) {
  .colors-section__list[data-v-2ae900ab] {
    gap: 20px;
    width: 100%;
  }
  .color{
    margin-right: 0;
  }
}
</style>
