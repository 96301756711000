<template>
  <section class="tos-page">
    <Header />
    <Terms />
    <Footer />
  </section>
</template>

<script>
import Header from "@/components/landing/Header";
import Footer from "@/components/landing/Footer";
import Terms from "@/components/Terms";

export default {
  components: {
    Header,
    Terms,
    Footer,
  },
};
</script>
<style scoped>

* {
  color: #3d3d3d;
}

.tos-page {
  width: 100%;
  box-sizing: border-box;
}

</style>
