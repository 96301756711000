import { createStore } from "vuex"
import createPersistedState from "vuex-persistedstate";
import router from '../router/router';
import { Magic, SDKError, RPCError, ExtensionError } from 'magic-sdk';
import mainApi from '../utils/MainApi';
import { extensionId } from '../utils/constants';
import { detect } from 'detect-browser';

const browser = detect();

function isBrave() {
  if (window.navigator.brave != undefined) {
    if (window.navigator.brave.isBrave.name == "isBrave") {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

const magicKey = new Magic(process.env.VUE_APP_MAGIC_API_KEY);

function getCreatedDate(initialDate){
  const createdDate = new Date(initialDate);
  const createdDay = (createdDate.getDate().length < 2 ? "0" : "") + createdDate.getDate();
  const createdMonth = ((createdDate.getMonth()+1+"").length < 2 ? "0" : "") + (createdDate.getMonth() + 1);
  const createdYear = (createdDate.getFullYear()+"").slice(-2);
  return `${createdDay}/${createdMonth}/${createdYear}`;
}

export default createStore({
  state: {
    user: null,
    palettes: [],
    onePalette: {},
    userToken: '',
    isBannerShow: true,
    checkingLoginML: true,
    browser: isBrave() ? 'brave' : browser ? browser.name : 'chrome',

  },
  mutations: {
    setUserToken(state, userTokenData) {
      state.userToken = userTokenData;
    },
    setUser(state, userData) {
      state.user = userData;
    },
    setPalettes(state, palettesData) {
      state.palettes = palettesData;
    },
    setOnePalette(state, OnePaletteData) {
      state.onePalette = OnePaletteData;
    },
    setBannerHide(state, isHide) {
      state.isBannerShow = !isHide;
    },
    setCheckingLoginML(state, isChecking) {
      state.checkingLoginML = isChecking;
    },
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    palettes: (state) => {
      return state.palettes;
    },
    onePalette: (state) => {
      return state.onePalette;
    },
    userToken: (state) => {
      return state.userToken;
    },
    isBannerShow: (state) => {
      return state.isBannerShow;
    },
    checkingLoginML: (state) => {
      return state.checkingLoginML;
    },
    browser: (state) => {
      return state.browser;
    },
  },
  actions: {
    async login({ commit }, email) {
      try {
        const didToken = await magicKey.auth.loginWithMagicLink(email);
        await mainApi.login(didToken)
        .then(data => {
          commit('setUserToken', data.token);
          if (typeof chrome === "undefined" || !chrome.runtime) {
            localStorage.setItem('palette.site_login', data.token);
            window.postMessage("externally_connectable.login")
          } else if (chrome.runtime) {
            chrome.runtime.sendMessage(extensionId, {
              command: "palette.site_login",
              token: data.token
            });
          }
        })
        .catch(err => console.log(err));
          mainApi.getUser(this.state.userToken).then((data) => {
            if (data.user.first_time) {
              router.push({ path: '/welcome' })
            }
            else {
              router.push({ path: '/profile' })
            }
          })
      }
      catch (error) {
        if (error instanceof SDKError) {
          console.log('SDKError')
          console.log(error)
        }
        if (error instanceof RPCError) {
          console.log('RPCError')
          console.log(error)
        }
        if (error instanceof ExtensionError) {
          console.log('ExtensionError')
          console.log(error)
        }
      }
    },
    async logout({ commit }) {
      router.push({ path: '/' })
      mainApi.logout(this.state.userToken);
      if (typeof chrome === "undefined" || !chrome.runtime) {
        localStorage.removeItem('palette.site_login');
        window.postMessage("externally_connectable.logout")
      } else if (chrome.runtime) {
        chrome.runtime.sendMessage(extensionId, {
          command: "palette.site_logout"
        });
      }
      commit('setUser', null);
      commit('setUserToken', '');
      commit('setPalettes', []);
      commit('setOnePalette', {});
      commit('setBannerHide', false);
      commit('setCheckingLoginML', false);
    },
    setUser({ commit }, userData){
      commit('setUser', userData);
    },
    setPalettes({ commit }, data){
      if (data.message) {
        router.push({ path: '/profile' });
      }
      else {
        data.palettes.forEach(item => {
          item.createdDate = getCreatedDate(item.created_at);
        });
        data.palettes.sort((a,b) => (new Date(b.created_at) - new Date(a.created_at)) );
        commit('setPalettes', data.palettes);
      }
    },
    setOnePalette({ commit }, data){
      commit('setOnePalette', data.palette);
    },
    async deletePalette({ commit }, paletteId) {
      mainApi.deletePalette(this.state.userToken, paletteId)
      .then(res => {
        commit('setPalettes', this.state.palettes.filter(item => item._id !== res._id));
      })
      .catch((err)=>{
        console.log(err);
      });
    },
    async sendPayment({ commit }, isLifetime) {
      mainApi.sendPayment(this.state.userToken, isLifetime)
      .then(res => {
        window.location.href = res.url;
      })
      .catch((err)=>{
        console.log(err);
      });
    },
    async changePlan({ commit }) {
      mainApi.changePlan(this.state.userToken)
      .then(res => {
        window.location.href = res.url;
      })
      .catch((err)=>{
        console.log(err);
      });
    },
    async openPalette({commit}, paletteId) {
      router.push({ path: '/palette', query: { id: paletteId } });
    },
    setBannerHide({ commit }, isHide){
      commit('setBannerHide', isHide);
    },
    openNotFoundPage() {
      router.push({ path: '/not-found'});
    },
    async checkLoginML({ commit }) {
      try {
        commit('setCheckingLoginML', true);
        const isLoggedIn = await magicKey.user.isLoggedIn();
        if (isLoggedIn) {
          if (this.state.userToken) {
            router.push({ path: '/profile' });
            //commit('setCheckingLoginML', false);
          } else {
            const idToken = await magicKey.user.getIdToken();
            await mainApi.login(idToken)
            .then(data => {
              commit('setUserToken', data.token);
              if (typeof chrome === "undefined" || !chrome.runtime) {
                localStorage.setItem('palette.site_login', data.token);
                window.postMessage("externally_connectable.login")
              } else if (chrome.runtime) {
                chrome.runtime.sendMessage(extensionId, {
                  command: "palette.site_login",
                  token: data.token
                });
              }
              router.push({ path: '/profile' });
              //commit('setCheckingLoginML', false);
            })
            .catch(err => console.log(err));
          }
        } else {
          commit('setCheckingLoginML', false);
        }
      }
      catch (error) {
        console.log(error)
      }
    },
  },
  modules: {
  },
  plugins: [createPersistedState()],
})